import { useTheme } from "@mui/material";
import React from "react";

import GameDiscoverCoLogo from "../icon/GameDiscoverCoLogo";
import SponsorIcon from "../icon/SponsorIcon";

const HeaderHero = ({ includeSponsor = false }: HeaderHeroProps) => {
  const theme = useTheme();

  return (
    <header style={headerStyle}>
      <div
        style={{
          ...gdcoImageStyle,
          color:
            theme.palette.mode === "light"
              ? "#1d1d1b"
              : theme.palette.background.default,
        }}
      >
        <GameDiscoverCoLogo />
      </div>
      {includeSponsor && (
        <div style={adminImageStyle}>
          <SponsorIcon />
        </div>
      )}
    </header>
  );
};

type HeaderHeroProps = { includeSponsor?: boolean };

export default HeaderHero;

const headerStyle = {
  maxWidth: "45rem",
  width: "100%",
  padding: "0.5rem 0.5rem",
  margin: "0 auto",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
} as const;
const gdcoImageStyle = {
  minHeight: "14.25rem",
  width: "100%",
  maxWidth: "45rem",
  objectFit: "contain",
} as const;
const adminImageStyle = {
  minHeight: "4rem",
  width: "100%",
  maxWidth: "22.5rem",
  margin: "1.5rem auto 0 auto",
  paddingRight: "1.6rem",
  objectFit: "cover",
} as const;
