import type { ReactNode } from "react";
import React, { Component } from "react";

import type { ErrorOption } from "./ErrorContent";
import ErrorContent from "./ErrorContent";
import ErrorPage from "./ErrorPage";

import type { SeoProps } from "@gdco/fe-core/components/util/Seo";
import { linkTo, seoTitlePostfix } from "@gdco/fe-core/fetch/route-internal";

/**
 * @see https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html#introducing-error-boundaries
 */
export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: unknown) {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    this.setState({ error });
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      /* --- render fallback UI --- */
      if (this.props.fallback !== undefined) {
        return typeof this.props.fallback === "function"
          ? this.props.fallback(this.state)
          : this.props.fallback;
      }

      /* --- render default UI --- */
      if (this.props.fullErrorPage === false) {
        return (
          <ErrorContent
            heading={heading}
            subheading={subheading}
            options={contactOptions}
            error={this.state.error}
          />
        );
      } else {
        return (
          <ErrorPage
            heading={heading}
            subheading={subheading}
            seo={seo}
            options={contactOptions}
            error={this.state.error}
          />
        );
      }
    }
    /* --- no error => render children untouched --- */
    return this.props.children;
  }
}
type ErrorBoundaryProps = {
  children: ReactNode;
  /** @default true */
  fullErrorPage?: boolean;
  /** Fallback to render if an error occurs. Defaults to ErrorPage/ErrorContent. */
  fallback?: null | ReactNode | ((props: ErrorBoundaryState) => JSX.Element);
};

type ErrorBoundaryState = {
  hasError: boolean;
  error?: unknown;
};

const heading = "The site crashed unexpectedly 💣";
const subheading = "Notify us so we can fix it as soon as possible";
const contactOptions: ErrorOption[] = [
  { caption: "Message us on ", link: linkTo.discord, linkLabel: "Discord" },
  { caption: "Contact us per mail: ", email: linkTo.email },
  { caption: "Notify us on ", link: linkTo.twitter, linkLabel: "Twitter" },
];
const seo: SeoProps = {
  title: "Error" + seoTitlePostfix,
  description: null,
};
