import { LinearProgress, styled } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import type { ReactNode } from "react";
import React from "react";
import { useSpinDelay } from "spin-delay";

import ErrorBoundary from "../error/ErrorBoundary";

import {
  NavigationStateProvider,
  useNavigationState,
} from "./NavigationStateContext";
import SidePanel from "./SidePanel";

import { ProNavigationState } from "@gdco/fe-core/util/config";

const DashboardLayout = (props: { children: ReactNode }) => {
  return (
    <NavigationStateProvider>
      <DashboardLayoutContent>{props.children}</DashboardLayoutContent>
    </NavigationStateProvider>
  );
};

const DashboardLayoutContent = (props: { children: ReactNode }) => {
  const fetchersRunning = useIsFetching();
  const showLoading = useSpinDelay(fetchersRunning > 0);

  const navState = useNavigationState();

  return (
    <DashboardContainer>
      <SidePanel />
      <MainPanel state={navState.navigation} isMobile={navState.isMobile}>
        <ErrorBoundary fullErrorPage={false}>{props.children}</ErrorBoundary>
      </MainPanel>

      {showLoading && (
        <div style={{ width: "100%", position: "absolute", top: 0 }}>
          <LinearProgress />
        </div>
      )}
    </DashboardContainer>
  );
};

export default DashboardLayout;

const DashboardContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  overflow-x: hidden;
  scrollbar-gutter: stable;
`;

const fullWidthDesktop = "18.75rem";
const fullWidthMobile = "calc(100vw - 4.375rem)";
export const getFullWidth = (isMobile: boolean) => {
  return isMobile ? fullWidthMobile : fullWidthDesktop;
};
export const getMainContentMargin = (isMobile: boolean, state: number) => {
  return state === ProNavigationState.FULL
    ? getFullWidth(isMobile)
    : state === ProNavigationState.SMALL
      ? "7.5rem"
      : "0";
};

const MainPanel = styled("div")<{ state: number; isMobile: boolean }>`
  margin-left: ${(props) => getMainContentMargin(props.isMobile, props.state)};
  width: ${(props) =>
    props.state === ProNavigationState.FULL
      ? `calc(100% - ${getFullWidth(props.isMobile)})`
      : props.state === ProNavigationState.SMALL
        ? "calc(100% - 7.5rem)"
        : "100%"};
  display: block;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
  transition:
    margin-left 0.2s ease-in-out,
    width 0.2s ease-in-out;
`;
