import { styled } from "@mui/material";

import AnyLink from "@gdco/fe-core/components/util/AnyLink";

export const MutedLink = styled(AnyLink)(
  ({ theme }) => `
  color: ${theme.palette.text.disabled};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`,
);
