/* eslint-disable unicorn/filename-case -- Gatsby convention */

/**
 * Base Gatsby browser config
 *
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import type { GatsbyBrowser } from "gatsby";

/**
 * @see https://www.gatsbyjs.org/docs/add-offline-support-with-a-service-worker/#displaying-a-message-when-a-service-worker-updates
 * @see https://github.com/gatsbyjs/gatsby/issues/9087
 * onServiceWorkerUpdateFound is NOT the right place to trigger an update but onServiceWorkerUpdateReady is.
 */
export const onServiceWorkerUpdateReady: GatsbyBrowser["onServiceWorkerUpdateReady"] =
  () => window.location.reload();

/* eslint-enable unicorn/filename-case */
