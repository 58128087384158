import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import type { User } from "@gdco/fe-core/context/UserContext";
import { useUser } from "@gdco/fe-core/context/UserContext";
import { ROLE_LIST } from "@gdco/fe-core/roles";
import type { Role } from "@gdco/fe-core/roles";
import { gdcoStorage } from "@gdco/fe-core/util/storage";

/**
 * Dialog for setting fake roles for presentational purposes
 *
 * This is only available to admins.
 */
const FakeRolesDialog = (props: FakeRolesDialogProps) => {
  const user = useUser();

  const [fakeRoles, setFakeRoles] = useState<Role[]>(() => {
    return user.user?.fakeRoles ?? [];
  });

  if (
    user.user === null ||
    !user.hasAnyRole(["admin"], { ignoreFakeRoles: true })
  ) {
    props.onClose?.();
    return null;
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Set fake roles</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Enter a list of roles to set as fake roles for this session.
          <br />
          If the list is empty, you won&apos;t have any special roles.
          <br />
          The page will be reloaded when you set the fake roles to apply them.
        </DialogContentText>
        <DialogContentText mb={2} variant="body2">
          Your current real roles are: {user.user.roles.join(", ")}
          <br />
          Your current fake roles are:{" "}
          {user.user.fakeRoles === undefined
            ? "none"
            : user.user.fakeRoles.length === 0
              ? "no special roles (normal user)"
              : user.user.fakeRoles.join(", ")}
        </DialogContentText>

        <Autocomplete
          multiple
          options={ROLE_LIST}
          value={fakeRoles ?? []}
          onChange={(_event, nextValue) => {
            setFakeRoles(nextValue);
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            storeFakeRoles({
              user,
              fakeRoles: [],
              removeFakeRoles: true,
              onClose: props.onClose,
            });
          }}
        >
          Clear
        </Button>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={() => {
            storeFakeRoles({
              user,
              fakeRoles,
              onClose: props.onClose,
            });
          }}
        >
          Set
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type FakeRolesDialogProps = {
  /** Whether the dialog is open */
  open: boolean;
  /**
   * On close callback
   *
   * The page will be reloaded on save.
   */
  onClose?: () => void;
};

export const storeFakeRoles = (opts: {
  user: User;
  fakeRoles: Role[];
  removeFakeRoles?: boolean;
  onClose?: () => void;
}) => {
  if (opts.user.user === null) {
    return;
  }
  const userInfo = opts.user.user;
  if (opts.removeFakeRoles) {
    delete userInfo.fakeRoles;
  } else {
    userInfo.fakeRoles = opts.fakeRoles;
  }
  gdcoStorage.local.setItem("userInfo", userInfo);
  window.location.reload();
  opts.onClose?.();
};

export default FakeRolesDialog;
