import { Favorite } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { noop } from "lodash";
import ms from "ms";
import React, { createContext, useContext } from "react";

import { useUser } from "@gdco/fe-core/context/UserContext";
import { fetchBackend } from "@gdco/fe-core/fetch/fetch";
import { reactQueryClient } from "@gdco/fe-core/fetch/QueryClient";
import { routeExternal } from "@gdco/fe-core/fetch/route-external";
import { featureRoles } from "@gdco/fe-core/roles";

const STALE_TIME = ms("5m");
const QUERY_KEY = "FavoriteApps";

const COLOR_FAVORITE = "error";
const COLOR_NOT_FAVORITE = "disabled";
const getColor = (isFavorite: boolean) =>
  isFavorite ? COLOR_FAVORITE : COLOR_NOT_FAVORITE;

/**
 * Returns a favorite icon
 */
export function getFavoriteIcon(isFavorite: boolean) {
  return <Favorite color={getColor(isFavorite)} />;
}

const FavoriteAppsContext = createContext<FavoriteAppsProviderContext>({
  appids: [],
  enabled: false,
  isFavoriteAppid: (_appid) => false,
  toggleAppid: noop,
  tooltip: (_isFavorite) => "",
  color: (_isFavorite) => "",
  icon: (_isFavorite) => <></>,
});

export type FavoriteAppsProviderContext = {
  appids: number[];
  enabled: boolean;
  isFavoriteAppid: (appid: number) => boolean;
  toggleAppid: (appid: number) => void;
  tooltip: (isFavorite: boolean) => string;
  color: (isFavorite: boolean) => string;
  icon: (isFavorite: boolean) => JSX.Element;
};

/**
 * Returns favorite apps
 */
export const useFavoriteApps = () => useContext(FavoriteAppsContext);

export const FavoriteAppsProvider = (props: FavoriteAppsProviderProps) => {
  const { hasAnyRole } = useUser();

  const isEnabled = hasAnyRole(...featureRoles.favoriteApps);

  const favAppsQuery = useQuery<ApiResponse>({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchBackend("get", routeExternal.favoriteApps),
    enabled: isEnabled,
    staleTime: STALE_TIME,
  });

  const favoriteAppIds = favAppsQuery.data?.favoriteApps ?? [];

  const mutationAddFavoriteApp = useMutation({
    mutationFn: (appid: number) =>
      fetchBackend("patch", routeExternal.favoriteApps, { json: { appid } }),
    onMutate: async (appid) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await reactQueryClient.cancelQueries({
        queryKey: [QUERY_KEY],
      });

      // Snapshot the previous value
      const previousFavoriteAppids = reactQueryClient.getQueryData<
        ApiResponse | undefined
      >([QUERY_KEY]);

      // Optimistically update to the new value
      reactQueryClient.setQueryData<ApiResponse>([QUERY_KEY], (old) => ({
        favoriteApps: [...(old?.favoriteApps ?? []), appid],
      }));

      // Return a context object with the snapshotted value
      return { previousFavoriteAppids };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newFavoriteApp, context) => {
      reactQueryClient.setQueryData(
        [QUERY_KEY],
        context?.previousFavoriteAppids ?? [],
      );
    },
    onSettled: () => {
      void reactQueryClient.invalidateQueries({
        predicate: ({ queryKey }) => queryKey.includes(QUERY_KEY),
      });
    },
  });

  const mutationRemoveFavoriteApp = useMutation({
    mutationFn: (appid: number) =>
      fetchBackend("delete", routeExternal.favoriteApps, { json: { appid } }),
    onMutate: (appid) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      void reactQueryClient.cancelQueries({
        queryKey: [QUERY_KEY],
      });

      // Snapshot the previous value
      const previousFavoriteAppids = reactQueryClient.getQueryData<
        ApiResponse | undefined
      >([QUERY_KEY]);

      // Optimistically update to the new value
      reactQueryClient.setQueryData<ApiResponse>([QUERY_KEY], (old) => ({
        favoriteApps: (old?.favoriteApps ?? []).filter((id) => id !== appid),
      }));

      // Return a context object with the snapshotted value
      return { previousFavoriteAppids };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newFavoriteApp, context) => {
      reactQueryClient.setQueryData(
        [QUERY_KEY],
        context?.previousFavoriteAppids ?? [],
      );
    },
    onSettled: () => {
      void reactQueryClient.invalidateQueries({
        predicate: ({ queryKey }) => queryKey.includes(QUERY_KEY),
      });
    },
  });

  if (!isEnabled) {
    // Return a dummy context
    return (
      <FavoriteAppsContext.Provider
        value={{
          appids: [],
          enabled: false,
          isFavoriteAppid: (_appid) => false,
          toggleAppid: noop,
          tooltip: (_isFavorite) => "Toggle favorite",
          color: (_isFavorite) => COLOR_NOT_FAVORITE,
          icon: (_isFavorite) => <></>,
        }}
      >
        {props.children}
      </FavoriteAppsContext.Provider>
    );
  }

  const isFavoriteAppid = (appid: number) => favoriteAppIds.includes(appid);

  return (
    <FavoriteAppsContext.Provider
      value={{
        appids: favoriteAppIds,
        enabled: isEnabled,
        isFavoriteAppid,
        toggleAppid: (appid) => {
          isFavoriteAppid(appid)
            ? mutationRemoveFavoriteApp.mutate(appid)
            : mutationAddFavoriteApp.mutate(appid);
        },
        tooltip: (isFavorite) =>
          isFavorite ? "Remove from favorites" : "Add to favorites",
        color: getColor,
        icon: getFavoriteIcon,
      }}
    >
      {props.children}
    </FavoriteAppsContext.Provider>
  );
};

type FavoriteAppsProviderProps = {
  children: React.ReactNode;
};

type ApiResponse = {
  /** array of appids */
  favoriteApps: number[];
};
