import type { GatsbyLinkProps } from "gatsby";
import React, { forwardRef, useMemo } from "react";

import type { ExternalLinkExtraProps } from "./ExternalLink";
import ExternalLink from "./ExternalLink";
import { GatsbyLinkWithRef } from "./GatsbyFixes";

export type AnyLinkProps = Omit<
  GatsbyLinkProps<unknown> & ExternalLinkExtraProps,
  "ref"
>;

/**
 * A link that can be either internal or external
 */
const AnyLink = forwardRef<HTMLAnchorElement, AnyLinkProps>(
  (
    {
      children,
      to,

      // GatsbyLinkProps
      activeClassName,
      partiallyActive,

      // ExternalLinkExtraProps
      relType,

      ...linkProps
    },
    ref,
  ) => {
    const isExternal = useMemo(() => {
      return (
        to.startsWith("http") ||
        to.startsWith("//") ||
        to.startsWith("mailto:") ||
        to.startsWith("steam:")
      );
    }, [to]);

    if (isExternal) {
      return (
        <ExternalLink {...linkProps} href={to} relType={relType} ref={ref}>
          {children}
        </ExternalLink>
      );
    }

    return (
      <GatsbyLinkWithRef
        {...linkProps}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        innerRef={ref}
      >
        {children}
      </GatsbyLinkWithRef>
    );
  },
);

AnyLink.displayName = "AnyLink";

export default AnyLink;
