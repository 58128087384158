import type { LinkTypeMap } from "@mui/material";
import React, { forwardRef } from "react";

import AnyLink from "@gdco/fe-core/components/util/AnyLink";

/**
 * Link for use in MUI components like `Tab` which accept a different component
 * via the `component` prop but require specifying `href` instead of `to`.
 */
const MuiComponentLink = forwardRef<HTMLAnchorElement, MuiComponentLinkProps>(
  ({ href, children, ...props }, ref) => {
    return (
      <AnyLink {...(props as Record<string, unknown>)} to={href} ref={ref}>
        {children}
      </AnyLink>
    );
  },
);

MuiComponentLink.displayName = "MuiComponentLink";

type MuiComponentLinkProps = {
  href: string;
} & LinkTypeMap["props"];

export default MuiComponentLink;
