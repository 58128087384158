/*
 * List of available platforms for use all over the code.
 */

import {
  AllPlatformsIcon,
  PlaystationIcon,
  SteamIcon,
  XboxIcon,
} from "./icon/SvgIcon";
import { th } from "./pro-theme";

/** Platform keys used in API responses */
export const platformKeysShort = ["St", "Xb", "Ps"] as const;
/** Platform keys used in API responses */
export type PlatformKeyShort = (typeof platformKeysShort)[number];

/** Platform keys used in API responses with the special `Ap` (all) platform */
export const platformKeysShortWithAll = ["Ap", ...platformKeysShort] as const;
/** Platform keys used in API responses with the special `Ap` (all) platform */
export type PlatformKeyShortWithAll = (typeof platformKeysShortWithAll)[number];

/** Platform keys with full names */
export const platformKeysFull = ["steam", "xbox", "playstation"] as const;
/** Platform keys with full names */
export type PlatformKeyFull = (typeof platformKeysFull)[number];

/** Platform keys with full names with the special `all` platform */
export const platformKeysFullWithAll = ["all", ...platformKeysFull] as const;
/** Platform keys with full names with the special `all` platform */
export type PlatformKeyFullWithAll = (typeof platformKeysFullWithAll)[number];

/** Mapping of full platform keys to short platform keys */
export const platformsKeysMapping = {
  all: "Ap",
  steam: "St",
  xbox: "Xb",
  playstation: "Ps",
} satisfies Record<PlatformKeyFullWithAll, PlatformKeyShortWithAll>;

/** Mapping of short platform keys to full platform keys */
export const platformsKeysShortMapping = {
  Ap: "all",
  St: "steam",
  Xb: "xbox",
  Ps: "playstation",
} satisfies Record<PlatformKeyShortWithAll, PlatformKeyFullWithAll>;

/** Object with available platforms */
export const platformList = {
  St: {
    id: "St",
    name: "Steam",
    shortName: "Steam",
    color: th.steamBlueDark,
    icon: SteamIcon,
  },
  Xb: {
    id: "Xb",
    name: "Xbox",
    shortName: "Xbox",
    color: th.xboxGreen,
    icon: XboxIcon,
    subscription: {
      name: "Xbox Game Pass",
      shortName: "GP",
    },
  },
  Ps: {
    id: "Ps",
    name: "PlayStation",
    shortName: "PS",
    color: th.playstationBlueDark,
    icon: PlaystationIcon,
    subscription: {
      name: "PlayStation Plus",
      shortName: "PS+",
    },
  },
} satisfies PlatformListRecord<PlatformKeyShort>;

/** Object with available platforms */
export const platformListFull = {
  steam: { ...platformList.St, id: "steam" },
  xbox: { ...platformList.Xb, id: "xbox" },
  playstation: { ...platformList.Ps, id: "playstation" },
} satisfies PlatformListRecord<PlatformKeyFull>;

/** Object with available platforms + with a special `Ap` (all) platform */
export const platformListAll = {
  Ap: {
    id: "Ap",
    name: "All",
    shortName: "All",
    color: th.gameboyGreen,
    icon: AllPlatformsIcon,
  },
  ...platformList,
} satisfies PlatformListRecord<PlatformKeyShortWithAll>;

/** Object with available platforms + with a special `all` platform */
export const platformListAllFull = {
  all: {
    id: "all",
    name: "All",
    shortName: "All",
    color: th.gameboyGreen,
    icon: AllPlatformsIcon,
  },
  ...platformListFull,
} satisfies PlatformListRecord<PlatformKeyFullWithAll>;

/** Platform numbers used in the API */
export const platformApiNums = {
  St: 10,
  Xb: 11,
  Ps: 12,
} satisfies Record<PlatformKeyShort, number>;
export type PlatformApiNum =
  (typeof platformApiNums)[keyof typeof platformApiNums];

type PlatformListRecord<TPlatformList extends string> = Record<
  TPlatformList,
  PlatformInfoType<TPlatformList>
>;
export type PlatformInfoType<TPlatformList> = {
  id: TPlatformList;
  name: string;
  shortName: string;
  color: string;
  icon: typeof SteamIcon;
  subscription?: {
    name: string;
    shortName: string;
  };
};

export type PlatformInfo = PlatformInfoType<PlatformKeyFull>;
export type PlatformWithAllInfo = PlatformInfoType<PlatformKeyFullWithAll>;

/** Check if the provided platform is a valid platform */
export function isPlatform(platform: unknown): platform is PlatformKeyShort {
  return Object.keys(platformList).includes(platform as string);
}

/** Check if the provided platform is a valid platform */
export function isPlatformFull(platform: unknown): platform is PlatformKeyFull {
  return Object.keys(platformListFull).includes(platform as string);
}

/** Check if the provided platform is a valid platform from the list with the special `Ap` (all) platform */
export function isPlatformShortWithAll(
  platform: unknown,
): platform is PlatformKeyShortWithAll {
  return Object.keys(platformListAll).includes(platform as string);
}

/** Check if the provided platform is a valid platform from the list with the special `all` platform */
export function isPlatformFullWithAll(
  platform: unknown,
): platform is PlatformKeyFullWithAll {
  return Object.keys(platformListAllFull).includes(platform as string);
}

/** Dynamic type for the platform data returned by the API */
export type PlatformRowData = {
  [key in `is${PlatformKeyShort}`]: 0 | 1;
} & {
  [key in `url${PlatformKeyShort}`]: string | null;
};

const allowedIsPlatformKeys = new Set(
  platformKeysShort.map((platform) => `is${platform}`),
);

/**
 * Record of platform and store availability
 *
 * Store availability values:
 *   - string - URL to a store page
 *   - true - available but no store page
 *   - false - not available on the platform
 */
export type PlatformAvailability = Record<PlatformKeyFull, string | boolean>;

/** Type used in the column data for platform availability */
export type PlatformAvailabilityRecord = {
  platforms: PlatformAvailability;
};

/**
 * Get platform availability from the data returned by the API
 *
 * Requires the row to have columns starting with `is` and `url` for each platform (e.g. `isSt`, `urlXb`)
 */
export function getPlatformAvailability<TRow extends PlatformRowData>(
  row: TRow,
): PlatformAvailability {
  return Object.fromEntries(
    Object.entries(row)
      .filter(([key]) => allowedIsPlatformKeys.has(key))
      .map(([key, value]) => {
        const platformName = key.replace("is", "") as PlatformKeyShort;
        return [
          platformsKeysShortMapping[platformName],
          value === 0 ? false : row[`url${platformName}`] ?? true,
        ] as [PlatformKeyFull, string | boolean];
      }),
  ) as PlatformAvailability;
}

/**
 * Get the full image URL for the provided image
 */
export function getFullHeaderImage(imageSrc: string) {
  if (imageSrc.includes("/steam/apps/")) {
    // Replace Steam header with the large capsule image as it's larger
    imageSrc = imageSrc.replace("header.jpg", "capsule_616x353.jpg");
  }
  return imageSrc;
}
