/* eslint-disable unicorn/filename-case -- Gatsby convention */

/**
 * Layout wrapper for Plus and other subdomains
 */

import type { GatsbySSR } from "gatsby";
import React from "react";

import RootLayout from "../components/core/layout/RootLayout";

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => (
  <RootLayout>{element}</RootLayout>
);

/* eslint-enable unicorn/filename-case */
