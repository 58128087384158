import { CssBaseline } from "@mui/material";
import React from "react";

import Layout from "../layout/Layout";

import type { ErrorContentProps } from "./ErrorContent";
import ErrorContent from "./ErrorContent";

import type { SeoProps } from "@gdco/fe-core/components/util/Seo";
import Seo from "@gdco/fe-core/components/util/Seo";
import { seoTitlePostfix } from "@gdco/fe-core/fetch/route-internal";

const browser = typeof window !== "undefined" && window;

const DEFAULT_HEADING = "404 Page Not Found";
const DEFAULT_SUBHEADING =
  "The page you are looking for does not exist or was removed";

const ErrorPage = ({ seo, ...errorContentProps }: ErrorPageProps) => {
  if (!browser) return null;

  const heading = errorContentProps.heading ?? DEFAULT_HEADING;
  const subheading = errorContentProps.subheading ?? DEFAULT_SUBHEADING;

  return (
    <>
      <CssBaseline />
      <Layout>
        <Seo
          {...{
            title: heading + seoTitlePostfix,
            ...seo,
          }}
        />
        <ErrorContent
          {...{
            heading,
            subheading,
            ...errorContentProps,
          }}
        />
      </Layout>
    </>
  );
};

type ErrorPageProps = Partial<ErrorContentProps> & {
  seo?: SeoProps;
};

export default ErrorPage;
