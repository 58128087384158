import { noop } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useEventListener, useIsClient } from "usehooks-ts";

import { removeToken } from "../fetch/fetch-token";
import { reactQueryClient } from "../fetch/QueryClient";
import type { HasAnyRoleFn } from "../roles";
import type {
  LocalStorageCurrentTabEvent,
  LocalStorageDataKey,
  LocalStorageValue,
} from "../util/storage";
import {
  gdcoStorage,
  gdcoStorageData,
  getStorageKey,
  LOCAL_STORAGE_EVENT_CURRENT_TAB,
  LOCAL_STORAGE_EVENT_OTHER_TAB,
} from "../util/storage";

const UserContext = createContext<User>({
  user: null,
  isSignedIn: false,
  signOut: noop,
  hasAnyRole: () => false,
});

/**
 * Firebase user keys that are picked from the user object. Roles are added separately as they are not part of the user object.
 */
export const pickedFirebaseUserKeys = [
  "uid",
  "email",
  "displayName",
  "photoURL",
  "emailVerified",
  "metadata",
] as const;

export const LEGACY_LOGINS = new Set(["plus", "focusplus"]);

export type User = {
  /** Whether the user is signed in, automatically updated when the user changes */
  isSignedIn: boolean;
  /** The user object. Updated on load and when the local storage value changes. */
  user: LocalStorageValue<"userInfo"> | null;
  /** Sign out the user */
  signOut: () => void;
  /**
   * Whether the user is signed in and has any of the given roles
   *
   * If the user has the `admin` role, they are considered to have all roles, unless `opts.skipAdmin` is true.
   *
   * @param roles The roles to check
   *              - If null, always returns true
   *             - If empty array, returns false (for admin, depends on `opts.skipAdmin`)
   * @param opts Options
   */
  hasAnyRole: (
    roles: Parameters<HasAnyRoleFn>[1],
    opts?: Parameters<HasAnyRoleFn>[2],
  ) => boolean;
};

/**
 * Get the user context
 */
export function useUser() {
  return useContext(UserContext);
}

/**
 * Provider for the user context
 */
export const UserProvider = (props: UserProviderProps) => {
  const isClient = useIsClient();

  const [userInfo, setUserInfo] =
    useState<LocalStorageValue<"userInfo"> | null>(null);

  useEffect(() => {
    // Set the user info from local storage when the page loads

    const userToken = gdcoStorage.local.getItem("userToken");
    if (userToken === null) {
      // Force remove the user info from local storage in case it still exists
      gdcoStorage.local.removeItem("userInfo");
      return;
    }

    const userInfo = gdcoStorage.local.getItem("userInfo");
    if (userInfo === null) {
      // Force remove the user token from local storage in case it still exists
      gdcoStorage.local.removeItem("userToken");
      return;
    }

    if (LEGACY_LOGINS.has(userInfo.uid)) {
      // Legacy user info in Firebase auth flow, force remove the user token from local storage
      gdcoStorage.local.removeItem("userToken");
      return;
    }

    setUserInfo(userInfo);
  }, []);

  function doSignOut() {
    // Reset the user info when the user logs out
    setUserInfo(null);

    // Remove user-specific data from storage
    const keysToRemove: LocalStorageDataKey[] = ["appCompare"];
    for (const key of keysToRemove) {
      gdcoStorage.local.removeItem(key);
    }

    void reactQueryClient.clear();

    gdcoStorage.local.removeItem("userInfo");
  }

  useEventListener(
    LOCAL_STORAGE_EVENT_CURRENT_TAB,
    (event: LocalStorageCurrentTabEvent) => {
      if (event.detail.key === getStorageKey("userToken")) {
        const value = event.detail.value as LocalStorageValue<"userToken">;
        if (value === null) {
          doSignOut();
        }
      } else if (event.detail.key === getStorageKey("userInfo")) {
        // Set the user info from local storage when it changes
        const value = event.detail.value as LocalStorageValue<"userInfo">;
        setUserInfo(value);
      }
    },
  );

  useEventListener(LOCAL_STORAGE_EVENT_OTHER_TAB, (event) => {
    // Set the user info from local storage when it changes in another tab
    if (event.key === getStorageKey("userToken")) {
      const value = gdcoStorageData.userToken.schema.safeParse(event.newValue);
      if (!value.success || value.data === null) {
        doSignOut();
      }
    } else if (event.key === getStorageKey("userInfo")) {
      // Set the user info from local storage when it changes in another tab
      const value = gdcoStorageData.userInfo.schema.safeParse(event.newValue);
      setUserInfo(value.success ? value.data : null);
    }
  });

  const hasAnyRole: User["hasAnyRole"] = (roles, opts) => {
    if (opts?.allowSignedOut === true && userInfo === null) {
      return true;
    }

    if (userInfo === null) {
      return false;
    }

    if (roles === null) {
      // No roles, so access is always allowed
      return true;
    }

    if (roles.length === 0) {
      // Empty roles, so access is always denied
      return false;
    }

    const isUserAdmin = userInfo.roles.includes("admin");

    if (
      opts?.ignoreFakeRoles !== true &&
      isUserAdmin &&
      Array.isArray(userInfo.fakeRoles)
    ) {
      // Admins can have fake roles for presentation purposes
      return userInfo.fakeRoles.some((role) => roles?.includes(role));
    }

    if (opts?.ignoreAdmin !== true && isUserAdmin) {
      // Admins have all roles
      return true;
    }

    return roles.some((role) => userInfo.roles.includes(role));
  };

  // Prevent hydration mismatch by only rendering the children after the first render

  return (
    <UserContext.Provider
      value={{
        user: userInfo,
        isSignedIn: userInfo !== null,
        signOut: removeToken,
        hasAnyRole,
      }}
    >
      {isClient ? props.children : null}
    </UserContext.Provider>
  );
};

type UserProviderProps = {
  children: React.ReactNode;
};
