import type { WindowLocation } from "@gatsbyjs/reach-router";

/**
 * Use any time you want to make sure that the URL is safe and goes to the current server
 *
 * Useful for redirects, especially in the combo with `encodeURIComponent`.
 */
export function safeUrl(
  to: string | Location | WindowLocation,
  defaultRedirect = "/",
) {
  let url: string;
  if (typeof to === "string") {
    url = to;
  } else if (typeof to.pathname === "string") {
    url = to.pathname + to.search;
  } else {
    url = defaultRedirect;
  }

  if (!url.startsWith("/") || url.startsWith("//")) {
    return defaultRedirect;
  }

  return url;
}
