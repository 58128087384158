import ky from "ky";
import _get from "lodash/get";

import type { Role } from "../roles";
import { isSsgEnvironment } from "../util/config";
import type { LocalStorageValue } from "../util/storage";
import { gdcoStorage, gdcoStorageData } from "../util/storage";

import { handleFetchErrorReturning } from "./fetch-error";
import { routeExternal as route } from "./route-external";

export const createJwtRequest = (authHeader: string | null) => {
  return ky.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          if (authHeader !== null) {
            request.headers.set("Authorization", "Bearer " + authHeader);
          }
        },
      ],
    },
  });
};

export const getToken = (throwOnNull = true) => {
  if (isSsgEnvironment) return gdcoStorageData.userToken.default;
  const token = gdcoStorage.local.getItem("userToken");
  if (token === null && throwOnNull) {
    throw new Error("Not authenticated: token is missing");
  }
  return token;
};

export const removeToken = () => {
  if (isSsgEnvironment) return;
  gdcoStorage.local.removeItem("userToken");
};

export function setUserInfo(user: LocalStorageValue<"userInfo">) {
  gdcoStorage.local.setItem("userInfo", user);
}

export function getUserInfo() {
  const userInfo = gdcoStorage.local.getItem("userInfo");
  return userInfo;
}

type AuthenticateBody =
  | {
      success: false;
      error: {
        code: number;
        message: string;
      };
    }
  | {
      success: true;
      token: string;
      roles: Role[];
    };

export const fetchNewToken = async (
  opts: {
    group: typeof process.env.GATSBY_SUBDOMAIN;
  } & (
    | {
        /** Firebase */
        variant: "fb";
        /** Firebase ID token */
        payload: string;
      }
    | {
        /** Legacy (username + password) */
        variant: "legacy";
        username: string;
        password: string;
      }
  ),
): Promise<
  | {
      loginSuccessful: true;
      token: string;
      roles: Role[];
    }
  | {
      loginSuccessful: false;
    }
> => {
  const requestOptions = {
    json: opts,
  };
  try {
    const res = await ky
      .post(route.authenticate, requestOptions)
      .json<AuthenticateBody>();
    if (res.success && res.token !== null) {
      return { loginSuccessful: true, token: res.token, roles: res.roles };
    }
  } catch (error) {
    handleFetchErrorReturning(null, error);
  }
  return { loginSuccessful: false };
};
