import React from "react";

export default function GameDiscoverCoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1001.617 420">
      <title>GameDiscoverCo logo</title>
      <path
        d="M956.04,45.577V374.689H200.595c-90.882,0-164.556-73.674-164.556-164.556S109.714,45.577,200.595,45.577H956.04Z"
        fill="currentColor"
      />
      <g>
        <path
          d="M513.786,96.628c5.276,3.945,8.663,9.269,10.151,15.976h-13.784c-1.307-2.981-3.372-5.358-6.195-7.125-2.829-1.771-6.164-2.656-10.015-2.656-3.787,0-7.141,.87-10.06,2.608-2.917,1.74-5.185,4.224-6.798,7.453-1.616,3.229-2.423,7.019-2.423,11.364,0,6.892,1.847,12.265,5.543,16.112,3.693,3.851,8.738,5.776,15.136,5.776,4.718,0,8.772-1.398,12.156-4.19,3.384-2.796,5.573-6.677,6.567-11.643h-22.262v-9.409h33.253v12.668c-.931,4.348-2.75,8.353-5.449,12.016-2.702,3.663-6.243,6.598-10.618,8.802-4.378,2.204-9.393,3.305-15.045,3.305-6.458,0-12.156-1.41-17.092-4.236-4.936-2.826-8.757-6.768-11.455-11.831-2.702-5.061-4.054-10.852-4.054-17.371s1.352-12.31,4.054-17.371c2.699-5.061,6.504-9.02,11.41-11.877,4.903-2.856,10.585-4.284,17.046-4.284,8.008,0,14.654,1.971,19.933,5.913Z"
          fill="#eaf0f0"
        />
        <path
          d="M568.227,143.901h-26.361l-4.657,13.226h-13.878l24.217-65.017h14.997l24.123,65.017h-13.878l-4.563-13.226Zm-3.541-9.966l-9.688-27.759-9.684,27.759h19.372Z"
          fill="#eaf0f0"
        />
        <path
          d="M660.785,92.11v65.017h-13.041v-45.363l-16.673,45.363h-11.364l-16.67-45.175v45.175h-13.135V92.11h15.928l19.748,48.901,19.375-48.901h15.834Z"
          fill="#eaf0f0"
        />
        <path
          d="M683.271,101.798v17.046h23.286v10.06h-23.286v17.698h26.079v10.524h-39.214V91.367h39.214v10.43h-26.079Z"
          fill="#eaf0f0"
        />
        <path
          d="M517.14,228.32c-2.735,4.97-6.695,8.835-11.877,11.598-5.188,2.762-11.349,4.145-18.49,4.145h-23.38v-65.76h23.38c7.141,0,13.302,1.349,18.49,4.051,5.182,2.702,9.142,6.519,11.877,11.458,2.729,4.936,4.096,10.697,4.096,17.277,0,6.522-1.367,12.265-4.096,17.231Zm-15.045-1.583c3.881-3.726,5.822-8.942,5.822-15.649,0-6.768-1.941-12.016-5.822-15.743-3.881-3.723-9.36-5.588-16.44-5.588h-9.127v42.567h9.127c7.08,0,12.559-1.862,16.44-5.588Z"
          fill="#eaf0f0"
        />
        <path
          d="M540.948,178.303v65.76h-13.135v-65.76h13.135Z"
          fill="#eaf0f0"
        />
        <path
          d="M592.736,235.355c-1.677,2.826-4.16,5.091-7.453,6.798-3.293,1.71-7.295,2.562-12.016,2.562-4.657,0-8.817-.791-12.48-2.374-3.666-1.586-6.598-3.866-8.802-6.846s-3.463-6.458-3.772-10.433h13.972c.309,2.671,1.38,4.891,3.214,6.661,1.828,1.768,4.266,2.653,7.31,2.653,2.732,0,4.827-.728,6.289-2.189s2.189-3.335,2.189-5.634c0-2.05-.591-3.742-1.771-5.079-1.179-1.334-2.671-2.404-4.472-3.211s-4.284-1.74-7.45-2.796c-4.099-1.364-7.453-2.717-10.06-4.051-2.608-1.337-4.83-3.275-6.661-5.822s-2.747-5.87-2.747-9.966c0-5.652,2.035-10.109,6.101-13.368,4.069-3.259,9.454-4.891,16.161-4.891,6.956,0,12.498,1.677,16.628,5.03s6.473,7.826,7.031,13.414h-14.157c-.373-2.359-1.367-4.299-2.981-5.822-1.616-1.522-3.82-2.283-6.613-2.283-2.423,0-4.378,.64-5.87,1.91-1.489,1.273-2.235,3.12-2.235,5.543,0,1.925,.573,3.526,1.722,4.797,1.149,1.273,2.608,2.313,4.378,3.12,1.771,.81,4.175,1.71,7.219,2.702,4.221,1.492,7.653,2.887,10.291,4.19,2.641,1.307,4.891,3.263,6.755,5.87,1.862,2.608,2.793,6.025,2.793,10.245,0,3.353-.837,6.443-2.514,9.269Z"
          fill="#eaf0f0"
        />
        <path
          d="M650.585,183.985c5.403,4.224,8.975,9.966,10.712,17.231h-13.784c-1.431-3.541-3.651-6.334-6.661-8.384s-6.628-3.075-10.852-3.075c-3.478,0-6.613,.87-9.406,2.608-2.796,1.74-4.97,4.224-6.522,7.453s-2.329,7.019-2.329,11.364c0,4.284,.776,8.041,2.329,11.27s3.726,5.712,6.522,7.453c2.793,1.737,5.928,2.608,9.406,2.608,4.224,0,7.841-1.025,10.852-3.075s5.23-4.842,6.661-8.384h13.784c-1.737,7.265-5.309,13.011-10.712,17.231-5.4,4.224-12.201,6.334-20.397,6.334-6.273,0-11.816-1.41-16.628-4.236s-8.538-6.786-11.176-11.877c-2.641-5.091-3.96-10.867-3.96-17.325,0-6.522,1.319-12.325,3.96-17.419,2.638-5.091,6.349-9.048,11.131-11.877,4.782-2.823,10.339-4.236,16.673-4.236,8.196,0,14.997,2.11,20.397,6.334Z"
          fill="#eaf0f0"
        />
        <path
          d="M714.065,181.657c4.997,2.856,8.942,6.846,11.828,11.968,2.887,5.124,4.333,10.915,4.333,17.371s-1.446,12.265-4.333,17.419-6.831,9.16-11.828,12.016c-5,2.856-10.573,4.284-16.722,4.284s-11.722-1.428-16.719-4.284c-5-2.856-8.942-6.862-11.828-12.016-2.89-5.155-4.333-10.958-4.333-17.419s1.443-12.247,4.333-17.371c2.887-5.121,6.828-9.111,11.828-11.968,4.997-2.856,10.573-4.287,16.719-4.287s11.722,1.431,16.722,4.287Zm-26.873,10.43c-2.92,1.74-5.203,4.239-6.846,7.498-1.646,3.259-2.468,7.065-2.468,11.41s.822,8.15,2.468,11.413c1.643,3.259,3.927,5.758,6.846,7.498,2.917,1.737,6.301,2.608,10.151,2.608s7.235-.87,10.154-2.608c2.917-1.74,5.2-4.239,6.846-7.498,1.643-3.263,2.468-7.065,2.468-11.413s-.825-8.15-2.468-11.41c-1.646-3.259-3.93-5.758-6.846-7.498-2.92-1.737-6.304-2.608-10.154-2.608s-7.235,.87-10.151,2.608Z"
          fill="#eaf0f0"
        />
        <path
          d="M742.181,178.303l17.604,52.067,17.698-52.067h14.066l-23.286,65.76h-16.858l-23.286-65.76h14.063Z"
          fill="#eaf0f0"
        />
        <path
          d="M807.777,188.733v17.046h23.286v10.06h-23.286v17.698h26.079v10.524h-39.214v-65.76h39.214v10.43h-26.079Z"
          fill="#eaf0f0"
        />
        <path
          d="M875.12,244.063l-15.37-25.615h-5.773v25.615h-13.135v-65.76h25.057c7.638,0,13.459,1.862,17.465,5.588s6.007,8.632,6.007,14.718c0,4.967-1.383,9.127-4.145,12.48-2.762,3.353-6.752,5.588-11.968,6.707l16.391,26.267h-14.53Zm-21.143-34.651h10.803c7.514,0,11.27-3.32,11.27-9.966,0-3.165-.916-5.634-2.747-7.404s-4.672-2.656-8.523-2.656h-10.803v20.027Z"
          fill="#eaf0f0"
        />
        <path
          d="M513.504,270.921c5.403,4.224,8.975,9.966,10.712,17.231h-13.784c-1.431-3.541-3.651-6.334-6.661-8.384-3.011-2.05-6.628-3.075-10.852-3.075-3.478,0-6.613,.87-9.406,2.608-2.796,1.74-4.97,4.224-6.522,7.453s-2.329,7.019-2.329,11.364c0,4.284,.776,8.041,2.329,11.27s3.726,5.712,6.522,7.453c2.793,1.737,5.928,2.608,9.406,2.608,4.224,0,7.841-1.025,10.852-3.075,3.011-2.05,5.23-4.842,6.661-8.384h13.784c-1.737,7.265-5.309,13.011-10.712,17.231-5.4,4.224-12.201,6.334-20.397,6.334-6.273,0-11.816-1.41-16.628-4.236s-8.538-6.786-11.176-11.877c-2.641-5.091-3.96-10.867-3.96-17.325,0-6.522,1.319-12.325,3.96-17.419,2.638-5.091,6.349-9.048,11.131-11.877,4.782-2.823,10.339-4.236,16.673-4.236,8.196,0,14.997,2.11,20.397,6.334Z"
          fill="#eaf0f0"
        />
        <path
          d="M576.984,268.592c4.997,2.856,8.942,6.846,11.828,11.968,2.887,5.124,4.333,10.915,4.333,17.371s-1.446,12.265-4.333,17.419c-2.887,5.155-6.831,9.16-11.828,12.016-5,2.856-10.573,4.284-16.722,4.284s-11.722-1.428-16.719-4.284c-5-2.856-8.942-6.862-11.828-12.016-2.89-5.155-4.333-10.958-4.333-17.419s1.443-12.247,4.333-17.371c2.887-5.121,6.828-9.111,11.828-11.968,4.997-2.856,10.573-4.287,16.719-4.287s11.722,1.431,16.722,4.287Zm-26.873,10.43c-2.92,1.74-5.203,4.239-6.846,7.498-1.646,3.259-2.468,7.065-2.468,11.41s.822,8.15,2.468,11.413c1.643,3.259,3.927,5.758,6.846,7.498,2.917,1.737,6.301,2.608,10.151,2.608s7.235-.87,10.154-2.608c2.917-1.74,5.2-4.239,6.846-7.498,1.643-3.263,2.468-7.065,2.468-11.413s-.825-8.15-2.468-11.41c-1.646-3.259-3.93-5.758-6.846-7.498-2.92-1.737-6.304-2.608-10.154-2.608s-7.235,.87-10.151,2.608Z"
          fill="#eaf0f0"
        />
      </g>
      <g>
        <path
          d="M324.149,342.79h29.239v-123.353H164.732c3.934,16.406,18.457,28.703,35.924,29.239h123.493v94.114Z"
          fill="#ffbb24"
        />
        <path
          d="M201.838,125.304c-47.069,0-85.226,38.157-85.226,85.226s38.156,85.225,85.224,85.225h104.515v-29.238h-104.515c-30.92-.002-55.985-25.067-55.985-55.987s25.066-55.987,55.986-55.987h198.611v-29.239H201.838Z"
          fill="#27c968"
        />
        <path
          d="M371.186,342.79h29.239V172.4H200.661c-17.467,.536-31.991,12.833-35.924,29.239h206.45v141.15Z"
          fill="#fa1e14"
        />
        <path
          d="M201.838,78.267c-73.046,0-132.262,59.216-132.262,132.262s59.209,132.253,132.25,132.257l104.526,.003v-29.236h-104.433c-56.897,0-103.021-46.123-103.024-103.02h0c-.002-56.9,46.124-103.028,103.024-103.028h198.53v-29.239H201.838Z"
          fill="#155dff"
        />
      </g>
    </svg>
  );
}
