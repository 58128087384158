import type { EffectCallback } from "react";
import { useEffect, useRef, useState } from "react";

/**
 * Hook to initialize and return a constant value. Unlike `React.useMemo`, this is guaranteed to
 * always return the same value (and if the initializer is a function, only call it once).
 * This is similar to setting a private member in a class constructor.
 *
 * If the value should ever change based on dependencies, use `React.useMemo` instead.
 *
 * @param initialValue - Initial value, or function to get the initial value. Similar to `useState`,
 * only the value/function passed in the first time this is called is respected.
 * @returns The value. The identity of this value will always be the same.
 *
 * @see https://github.com/microsoft/fluentui/blob/master/packages/react-hooks/src/useConst.ts
 * @license MIT
 */
export function useConst<T>(initialValue: T | (() => T)): T {
  // Use useRef to store the value because it's the least expensive built-in hook that works here
  // (we could also use `const [value] = React.useState(initialValue)` but that's more expensive
  // internally due to reducer handling which we don't need)
  const ref = useRef<{ value: T }>();
  if (ref.current === undefined) {
    // Box the value in an object so we can tell if it's initialized even if the initializer
    // returns/is undefined
    ref.current = {
      value:
        typeof initialValue === "function"
          ? (initialValue as () => T)()
          : initialValue,
    };
  }
  return ref.current.value;
}

/**
 * Hook to store a value and generate callbacks for setting the value to true or false.
 * The identity of the callbacks will always stay the same.
 *
 * @returns Array with the current value and an object containing the updater callbacks.
 *
 * @see https://github.com/microsoft/fluentui/blob/master/packages/react-hooks/src/useBoolean.ts
 * @license MIT
 */
export const useBoolean = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);
  const setTrue = useConst(() => {
    return () => setValue(true);
  });
  const setFalse = useConst(() => {
    return () => setValue(false);
  });
  const toggle = useConst(() => {
    return () => setValue((prev) => !prev);
  });
  return [value, { setTrue, setFalse, toggle }] as const;
};

/**
 * Returns true after the first render and false after the component is unmounted
 */
export function useIsMounted() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  return isMounted;
}

/**
 * Custom hook for determining if the component is rendering for the first time.
 *
 * @deprecated Don't use this hook, it's an anti-pattern.
 * @returns {boolean} A boolean value indicating whether the component is rendering for the first time.
 * @example
 * const isFirstRender = useIsFirstRender();
 * // Use isFirstRender to conditionally execute code only on the initial render.
 */
export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

/**
 * A hook that runs an effect only once (at mount).
 * @param {EffectCallback} effect - The effect to run.
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-effect-once)
 * @example
 * useEffectOnce(() => {
 *   console.log('Hello World');
 * });
 */
export function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this once
  useEffect(effect, []);
}
