import { useTheme } from "@mui/material";
import Highcharts, { setOptions } from "highcharts";
import HighchartsStock from "highcharts/highstock";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsOfflineExporting from "highcharts/modules/offline-exporting";
import { merge } from "lodash";
import type { ReactNode } from "react";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { abbreviateNumber } from "../components/core/util/render-format";

import { useEffectOnce } from "@gdco/fe-core/hooks/hooks";
import { baseUrlProduction } from "@gdco/fe-core/util/base-url";
import { isSsgEnvironment, subdomainName } from "@gdco/fe-core/util/config";

/**
 * This hook initializes Highcharts and Highcharts Stock with the theme
 */
function useHighchartsOptions() {
  const theme = useTheme();

  const [highchartsOptions, setHighchartsOptions] =
    useState<Highcharts.Options>({});

  const gdcoCredits: Highcharts.CreditsOptions = useMemo(
    () => ({
      enabled: true,
      text: `GameDiscoverCo ${subdomainName}: ${
        baseUrlProduction[process.env.GATSBY_SUBDOMAIN]
      }`,
      href: baseUrlProduction[process.env.GATSBY_SUBDOMAIN],
      style: {
        color: theme.palette.text.primary,
        fontSize: "8px",
      },
      position: {
        align: "left",
        x: 20,
        y: -5,
      },
    }),
    [theme.palette.text.primary],
  );

  useEffect(() => {
    const highchartsConfig: Highcharts.Options = {
      chart: {
        backgroundColor: "transparent",
        events: {
          fullscreenOpen: function () {
            // @ts-expect-error Highcharts typings are incorrect
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- Highcharts typings are incorrect
            this.update({
              chart: {
                backgroundColor: theme.palette.background.paper,
              },
            });
          },
          fullscreenClose: function () {
            // @ts-expect-error Highcharts typings are incorrect
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- Highcharts typings are incorrect
            this.update({
              chart: {
                backgroundColor: "transparent",
              },
            });
          },
          beforePrint: function () {
            this.update({
              credits: gdcoCredits,
            });
          },
          afterPrint: function () {
            this.update({
              credits: {
                enabled: false,
              },
            });
          },
        },
      },
      credits: {
        enabled: false,
      },
      lang: {
        decimalPoint: ".",
        thousandsSep: ",",
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
        useUTC: true,
      },
      yAxis: {
        labels: {
          formatter: function () {
            return typeof this.value === "number"
              ? abbreviateNumber(this.value)
              : this.value;
          },
        },
      },
      tooltip: {
        useHTML: true,
        style: {
          zIndex: theme.zIndex.tooltip,
        },
      },
      exporting: {
        enabled: true,
      },
    };

    const highchartsSharedTheme: Highcharts.Options = {
      title: {
        style: {
          color: theme.palette.text.primary,
        },
      },
      tooltip: {
        backgroundColor: theme.tooltip.backgroundColor,
        borderColor: theme.palette.divider,
        style: {
          color: theme.palette.text.primary,
        },
      },
      legend: {
        itemStyle: {
          color: theme.palette.text.secondary,
        },
        itemHoverStyle: {
          color: theme.palette.text.primary,
        },
        itemHiddenStyle: {
          color: theme.palette.text.disabled,
        },
      },
      xAxis: {
        lineColor: theme.palette.divider,
        labels: {
          style: {
            color: theme.palette.text.disabled,
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: theme.palette.text.disabled,
          },
        },
        stackLabels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        gridLineColor: theme.palette.divider,
        lineColor: theme.palette.divider,
      },
      plotOptions: {
        series: {
          borderColor: theme.palette.divider,
          dataLabels: {
            color: theme.palette.text.primary,
          },
        },
        timeline: {
          dataLabels: {
            color: theme.palette.text.primary,
            backgroundColor: theme.tooltip.backgroundColor,
          },
          marker: {
            lineColor: "transparent",
          },
        },
      },
      colorAxis: {
        minColor: theme.palette.background.default,
        maxColor: theme.palette.primary.light,
      },
      navigation: {
        menuStyle: {
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: theme.shadows[10],
        },
        menuItemStyle: {
          color: theme.palette.text.primary,
        },
        menuItemHoverStyle: {
          background: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            symbolStroke: theme.palette.text.primary,
            theme: {
              fill: theme.palette.background.paper,
              stroke: theme.palette.divider,
              // @ts-expect-error Highcharts typings are incorrect
              states: {
                hover: {
                  fill: theme.palette.primary.light,
                  stroke: theme.palette.primary.light,
                },
                select: {
                  fill: theme.palette.primary.light,
                  stroke: theme.palette.primary.light,
                },
              },
            },
          },
        },
        chartOptions: {
          chart: {
            backgroundColor: theme.palette.background.paper,
          },
          credits: gdcoCredits,
        },
      },

      // Highcharts Stock
      rangeSelector: {
        buttonTheme: {
          fill: theme.palette.background.paper,
          stroke: theme.palette.divider,
          "stroke-width": 1,
          style: {
            color: theme.palette.text.primary,
          },
          states: {
            hover: {
              fill: theme.palette.primary.light,
              style: {
                color: theme.palette.primary.contrastText,
              },
            },
            select: {
              fill: theme.palette.primary.main,
              style: {
                color: theme.palette.primary.contrastText,
              },
            },
          },
        },
      },
      scrollbar: {
        barBackgroundColor: theme.palette.background.paper,
        barBorderColor: theme.palette.divider,
        buttonArrowColor: theme.palette.text.primary,
        buttonBackgroundColor: theme.palette.background.paper,
        buttonBorderColor: theme.palette.divider,
        rifleColor: theme.palette.text.primary,
        trackBackgroundColor: theme.palette.background.paper,
        trackBorderColor: theme.palette.divider,
      },
      drilldown: {
        activeAxisLabelStyle: {
          color: theme.palette.text.primary,
        },
        activeDataLabelStyle: {
          color: theme.palette.text.primary,
        },
        breadcrumbs: {
          buttonTheme: {
            fill: theme.palette.background.paper,
            stroke: theme.palette.divider,
            "stroke-width": 1,
            style: {
              color: theme.palette.text.primary,
            },
            states: {
              hover: {
                fill: theme.palette.primary.light,
                style: {
                  color: theme.palette.primary.contrastText,
                },
              },
              select: {
                fill: theme.palette.primary.main,
                style: {
                  color: theme.palette.primary.contrastText,
                },
              },
            },
          },
        },
      },
    };

    const highchartsLightTheme: Highcharts.Options = {
      xAxis: {
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
    };

    const mergedHighchartsConfig = merge(
      {},
      highchartsConfig,
      highchartsSharedTheme,
      theme.palette.mode === "light" ? highchartsLightTheme : {},
    );

    // Initialize Highcharts

    setOptions(mergedHighchartsConfig);
    HighchartsStock.setOptions(mergedHighchartsConfig);

    setHighchartsOptions(mergedHighchartsConfig);
  }, [gdcoCredits, theme]);

  useEffectOnce(() => {
    // Enable global Highcharts modules

    highchartsAccessibility(Highcharts);
    highchartsAccessibility(HighchartsStock);

    highchartsExporting(Highcharts);
    highchartsExporting(HighchartsStock);

    highchartsOfflineExporting(Highcharts);
    highchartsOfflineExporting(HighchartsStock);

    highchartsExportData(Highcharts);
    highchartsExportData(HighchartsStock);
  });

  return highchartsOptions;
}

const HigchartsContext = createContext<ReturnType<typeof useHighchartsOptions>>(
  {},
);

/** Hook to get the Highcharts options */
export const useHighcharts = () => useContext(HigchartsContext);

/** Provider for the Highcharts options */
export const HighchartsProvider = (props: HighchartsProviderProps) => {
  const highchartsOptions = useHighchartsOptions();

  return (
    <HigchartsContext.Provider value={highchartsOptions}>
      {isSsgEnvironment || Object.keys(highchartsOptions).length > 0
        ? props.children
        : null}
    </HigchartsContext.Provider>
  );
};

type HighchartsProviderProps = { children: ReactNode };
