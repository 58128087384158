import type { ReactElement, ReactNode } from "react";
import React, { cloneElement, isValidElement } from "react";

import DashboardLayout from "./DashboardLayout";
import Header from "./Header";
import { PageTitle, ProPageLayout } from "./ProPage";

/** Fundamental layout which provides Header and Footer in a column style */
const Layout = ({
  children,
  title,
  contentAfter,
  header = <Header />,
}: LayoutProps) => {
  const DashboardContent = title ? (
    <ProPageLayout
      title={<PageTitle title={title} contentAfter={contentAfter} />}
    >
      {children}
    </ProPageLayout>
  ) : isValidElement(header) ? (
    cloneElement(header as ReactElement, {
      children,
    })
  ) : null;

  return <DashboardLayout>{DashboardContent}</DashboardLayout>;
};

type LayoutProps = {
  children?: ReactNode;
  header?: ReactNode;
  title?: string;
  contentAfter?: ReactNode;
};
export default Layout;
