import type { GatsbyLinkProps } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import React, { forwardRef } from "react";

/**
 * GatsbyLink doesn't work well with ref forwarding and Gatsby devs don't care.
 * @see https://github.com/gatsbyjs/gatsby/issues/34325
 */
export const GatsbyLinkWithRef = forwardRef<
  HTMLAnchorElement & GatsbyLink<unknown>,
  GatsbyLinkWithRefProps
>((props, ref) => {
  return <GatsbyLink {...props} innerRef={ref} />;
});

GatsbyLinkWithRef.displayName = "GatsbyLinkWithRef";

type GatsbyLinkWithRefProps = Omit<GatsbyLinkProps<unknown>, "ref">;
