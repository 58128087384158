import { navigate } from "gatsby";
import { StatusCodes } from "http-status-codes";
import _get from "lodash/get";
import ms from "ms";
import type { LiteralUnion } from "type-fest";

import { isProDomain } from "../util/config";
import { stringifyQueryString } from "../util/querystring";
import { gdcoStorage } from "../util/storage";
import { safeUrl } from "../util/url";

import { isKyHTTPError } from "./fetch";
import { routeInternal as route } from "./route-internal";

const RESET_429_AFTER_MS = ms("1h");
const MAX_429_COUNT = 3;

/** Reasons for a 401 error response returned from the API */
export enum AuthErrorReason {
  "Please log in again" = 1,
  "Invalid credentials" = 2,
  "Account disabled" = 3,

  // --- Known but unhandled errors, showing "Please log in again":

  // "Invalid JWT signature",

  // --- Extra errors which we want to show in the notification
  //     but don't have a specific message for in the API:
  "Too many requests. Please try again later." = 4,
}

/**
 * Override auth error messages from the API with custom messages
 *
 * Set to null to indicate that the message should be ignored.
 */
export const authErrorReasonMessages: Record<
  keyof typeof AuthErrorReason,
  string | null
> = {
  ...(Object.fromEntries(
    Object.keys(AuthErrorReason).map((key) => [key, key]),
  ) as Record<keyof typeof AuthErrorReason, string>),
  "Invalid credentials": null, // handled in the login form
};

/**
 * Handle fetch errors, returns a response with an error property
 *
 * Use this with useEffect.
 *
 * Old version of handleFetchError, kept for backwards compatibility.
 */
export function handleFetchErrorReturning(
  /** @deprecated This parameter isn't needed - response isn't available when catching a fetch error */
  _res: unknown,
  /** May be a response or a different kind of error */
  err: unknown,
  /** Will be added to the response */
  defaultData?: object,
) {
  return handleFetchErrorCommon("returning", _res, err, defaultData);
}

/**
 * Handle fetch errors
 *
 * Use this with React Query.
 *
 * @throws {FetchError} instead of returning a response
 */
export function handleFetchError(
  /** May be a response or a different kind of error */
  err: unknown,
) {
  handleFetchErrorCommon("throwing", {}, err);
}

export const unknownFetchErrorCode = StatusCodes.IM_A_TEAPOT;

function handleFetchErrorCommon(
  version: "returning" | "throwing",
  /** @deprecated This parameter isn't needed - response isn't available when catching a fetch error */
  _res: unknown,
  /** May be a response or a different kind of error */
  err: unknown,
  /** Will be added to the response */
  defaultData?: object,
) {
  const code: unknown = _get(err, "response.status", unknownFetchErrorCode);
  const result = {
    error: {
      success: false,
      code: code,
      message: _get(
        err,
        "response.statusText",
        _get(err, "message", "Unknown error"),
      ),
      fullError: err,
    },
    ...defaultData,
  };

  if (code === StatusCodes.UNAUTHORIZED) {
    // handle both use cases:
    // - 401 not authorized because user has no credentials
    // - 401 not authorized because JWT is deprecated
    gdcoStorage.local.removeItem("userToken");

    if (isKyHTTPError(err)) {
      void err.response.json().then((data) => {
        const responseMessage: unknown = _get(data, "error.message");
        finishRedirect(
          typeof responseMessage === "string" ? responseMessage : undefined,
        );
      });
    } else {
      finishRedirect();
    }

    // Silently return as we're navigating away
    if (version === "throwing") return;
  } else if (code === StatusCodes.TOO_MANY_REQUESTS) {
    const parsed429 = gdcoStorage.local.getItem("tooManyRequests");
    const diffTimestamp = Date.now() - parsed429.timestamp;
    if (diffTimestamp > RESET_429_AFTER_MS) {
      // Reset the count as it's been more than the max time
      gdcoStorage.local.setItem("tooManyRequests", {
        count: 1,
        timestamp: Date.now(),
      });
    } else if (parsed429.count >= MAX_429_COUNT) {
      // If we've hit the max count, reset the count and navigate away
      gdcoStorage.local.removeItem("tooManyRequests");

      if (isProDomain) {
        // Pro doesn't have an API page, so sign out and redirect to the login page
        gdcoStorage.local.removeItem("userToken");
        const reasonMessage: keyof typeof AuthErrorReason =
          "Too many requests. Please try again later.";
        finishRedirect(reasonMessage);
      } else {
        // Plus/Focus: Navigate to the API page
        void navigate(route.api.to);
      }
      // Silently return as we're navigating away
      if (version === "throwing") return;
    } else {
      // Increment the count and update the timestamp
      gdcoStorage.local.setItem("tooManyRequests", {
        count: parsed429.count + 1,
        timestamp: Date.now(),
      });
    }
  } else {
    result.error.success = _get(err, "response.ok", false);
  }

  console.error("error while fetching data", err, result);

  if (version === "returning") {
    return result;
  }

  throw new FetchError(code, result.error.message, result.error.fullError);
}

function finishRedirect(
  responseMessage?: LiteralUnion<keyof typeof AuthErrorReason, string>,
) {
  const searchParams: Record<string, unknown> = {};

  const reason: AuthErrorReason =
    AuthErrorReason[responseMessage as keyof typeof AuthErrorReason] ??
    AuthErrorReason["Please log in again"];
  searchParams.reason = reason;

  // If we are already on the login page while hitting an error,
  // we don't want to redirect to the login page again
  const isOnLoginPage = window.location.pathname.startsWith(route.login.to);
  if (isOnLoginPage) {
    const redirectTo = new URLSearchParams(window.location.search).get(
      "redirectTo",
    );
    if (redirectTo) {
      searchParams.redirectTo = redirectTo;
    }
  } else {
    // If we are not on the login page, we want to redirect to the current page
    searchParams.redirectTo = safeUrl(window.location);
  }

  void navigate(route.login.to + stringifyQueryString(searchParams));
}

export type FetchErrorType = ReturnType<typeof handleFetchErrorReturning>;

export class FetchError extends Error {
  constructor(
    public code: unknown,
    public message: string,
    public cause: unknown,
  ) {
    super(message, { cause });
    this.name = "FetchError";
  }
}

type StorageTooManyRequests429 = {
  count: number;
  timestamp: number;
};

export function hasTooManyRequests(parsed429: StorageTooManyRequests429) {
  const diffTimestamp = Date.now() - parsed429.timestamp;
  return diffTimestamp <= RESET_429_AFTER_MS && parsed429.count > MAX_429_COUNT;
}
