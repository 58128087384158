import type { AutocompleteProps } from "@mui/material";
import { Autocomplete, Grid, Tooltip } from "@mui/material";
import type { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { Link, navigate } from "gatsby";
import { debounce } from "lodash";
import React, { useMemo } from "react";

import { renderReleaseDate } from "../../core/util/release-date";
import { abbreviateNumber } from "../../core/util/render-format";

import { fetchBackend } from "@gdco/fe-core/fetch/fetch";
import { routeExternal } from "@gdco/fe-core/fetch/route-external";
import { routeInternal } from "@gdco/fe-core/fetch/route-internal";

type MuiAutocompleteProps = AutocompleteProps<
  Suggestion,
  undefined,
  undefined,
  undefined
>;

const SearchBoxPlus = (props: SearchBoxPlusProps) => {
  const mutation = useMutation<ApiResponse, unknown, string | null>({
    mutationKey: ["SearchBoxPlus"],
    mutationFn: (input) => {
      if (input === null || input.trim() === "") {
        return Promise.resolve({ suggestions: [] });
      }
      return fetchBackend("post", routeExternal.search, { json: { input } });
    },
  });

  const suggestions = useMemo(() => {
    if (mutation.data === undefined) {
      return [];
    }
    return mutation.data.suggestions.filter(
      (suggestion) => !props.omitAppids?.includes(suggestion.appid),
    );
  }, [mutation, props.omitAppids]);

  const debounceRequest = useMemo(() => {
    const debounceMs = 350;
    return debounce<NonNullable<MuiAutocompleteProps["onInputChange"]>>(
      (_event, value) => mutation.mutate(value),
      debounceMs,
      { leading: false },
    );
  }, [mutation]);

  return (
    <Autocomplete<Suggestion>
      fullWidth
      autoHighlight
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.appid === value.appid}
      getOptionLabel={(option) => option.name}
      options={suggestions}
      onInputChange={debounceRequest}
      disabled={props.disabled ?? false}
      loading={mutation.isPending}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          color={props.textColor}
          size="small"
          label={props.label ?? "Search Game Name"}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...props.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
          }}
          {...props.TextFieldProps}
        />
      )}
      onChange={(_event, option) => {
        if (option === null) {
          return;
        }
        const url = buildUrl(props, option);
        if (props.onChange) {
          props.onChange(option.appid);
        }
        void navigate(url);
      }}
      renderOption={(renderProps, option) => {
        const url = buildUrl(props, option);
        return (
          <li {...renderProps} key={option.appid}>
            <Tooltip
              placement="right"
              title={
                <p>
                  Release Date: {renderReleaseDate(option.releaseDate)}
                  <br />
                  Copies Sold: {abbreviateNumber(option.copiesSold)}
                  <br />
                  Revenue: ${abbreviateNumber(option.revenue)}
                </p>
              }
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item maxWidth="min(33%, 250px)">
                  {option.header && (
                    <img src={option.header} alt={option.name} />
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Link
                    to={url}
                    className="link"
                    css={{ width: "100%" }}
                    data-appid={option.appid}
                    data-name={option.name}
                    {...props.linkProps}
                  >
                    {option.name}
                  </Link>
                </Grid>
              </Grid>
            </Tooltip>
          </li>
        );
      }}
      componentsProps={{
        paper: {
          sx: {
            minWidth: "min(15rem, 100vw)",
          },
        },
      }}
    />
  );
};

type SearchBoxPlusProps = {
  /**
   * URL to navigate to when a suggestion is selected
   *
   * The `:appid` parameter will be replaced with the appid of the selected suggestion
   *
   * @default routeInternal.details.to + ":appid"
   */
  to?: string;
  label?: TextFieldProps["label"];
  textColor?: TextFieldProps["color"];
  /** Appids to omit from the search results */
  omitAppids?: number[];
  /** Disables the search box */
  disabled?: boolean;
  InputProps?: TextFieldProps["InputProps"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  TextFieldProps?: Exclude<TextFieldProps, "InputLabelProps" | "InputProps">;
  /**
   * Props to pass to the `Link` component
   *
   * @example
   * linkProps={{
   *   onClick: (e) => {
   *     e.preventDefault();
   *     const appid = Number(e.currentTarget.dataset.appid);
   *     const name = e.currentTarget.dataset.name;
   *   },
   * }}
   */
  linkProps?: Partial<React.ComponentPropsWithoutRef<typeof Link>>;
  /** Allow additional action on Autocomplete change  */
  onChange?: (appid: number) => void;
};

type Suggestion = {
  appid: number;
  name: string;
  header: string | null;
  copiesSold: number | null;
  revenue: number | null;
  releaseDate: string;
};

type ApiResponse = {
  suggestions: Suggestion[];
};

export default SearchBoxPlus;
function buildUrl(props: SearchBoxPlusProps, option: Suggestion) {
  let url = props.to ?? `${routeInternal.details.to}:appid/`;
  url = url.replace(":appid", option.appid.toString());
  return url;
}
