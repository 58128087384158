/* eslint-disable @typescript-eslint/no-magic-numbers -- math! */

/** Rounds a number to the nearest value */
export function nearestNumber(value: number, nearestNum = 1000): number {
  if (value === 0) {
    return 0;
  }
  value = value / nearestNum;
  value = (value < 0 ? Math.floor(value) : Math.ceil(value)) * nearestNum;
  return value;
}

/** Converts a percentage (0-100) to a decimal (0-1) */
export function pctToDecimal(pct: number): number {
  const multiplier = 0.01;
  return pct * multiplier;
}

/**
 * Calculates the percentage between two numbers
 *
 * @returns A percentage (0-100).
 * Both numbers must be non-null, positive, and the numerator must be less than or equal to the denominator.
 */
export function pctBetween(
  numerator: number | null,
  denominator: number | null,
) {
  if (
    numerator !== null &&
    denominator !== null &&
    denominator > 0 &&
    numerator >= 0 &&
    numerator <= denominator
  ) {
    return decimalToPct(numerator / denominator);
  }
  return null;
}

/** Converts a decimal (0-1) to a percentage (0-100) */
export function decimalToPct(decimal: number): number {
  const multiplier = 100;
  return decimal * multiplier;
}

export const centsToDollars = (cents: number): number => {
  const multiplier = 0.01;
  return cents * multiplier;
};

/**
 * Calculate the average an array of numbers.
 */
export const averageOfArray = (arr: number[]) => {
  if (arr.length === 0) return 0;
  const total = arr.reduce((a, b) => a + b, 0);
  return total / arr.length;
};

/**
 * Calculate the median from an already sorted array.
 * @param arr Sorted array.
 */
export const medianOfSortedArray = (arr: number[]) => {
  const len = arr.length;
  const mid = Math.ceil(len / 2);
  return len % 2 === 0 ? (arr[mid] + arr[mid - 1]) / 2 : arr[mid - 1];
};

/**
 * Calculate the median from an unsorted array.
 * @param arr Unsorted array.
 */
export const medianUnsortedArray = (arr: number[]) => {
  const sorted = arr.sort((a, b) => a - b);
  return medianOfSortedArray(sorted);
};

/* eslint-enable @typescript-eslint/no-magic-numbers */
