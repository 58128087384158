import type { ReactNode } from "react";
import React from "react";

import { PageTitle, ProPageLayout } from "./ProPage";

const Header = ({ title = "", children, contentAfter }: HeaderProps) => {
  return (
    <ProPageLayout
      title={<PageTitle title={title} contentAfter={contentAfter} />}
    >
      {children}
    </ProPageLayout>
  );
};

type HeaderProps = {
  title?: string;
  children?: ReactNode;
  contentAfter?: ReactNode;
};

export default Header;
