import type { HTTPError, Options } from "ky";
import { get } from "lodash";
import ms from "ms";
import { match } from "ts-pattern";

import { baseUrlProduction } from "../util/base-url";
import { isBrowserEnvironment } from "../util/config";

import { handleFetchError, handleFetchErrorReturning } from "./fetch-error";
import { createJwtRequest, getToken } from "./fetch-token";

const DEFAULT_FETCH_TIMEOUT_MS = ms("30s");

type FetchMethod = "get" | "post" | "put" | "patch" | "delete";

type FetchOptions<TMethod extends FetchMethod> = {
  json?: TMethod extends "get" ? undefined : unknown;
  /** Return full response instead of just the data property */
  fullResponse?: boolean;
  /** @default false */
  allowUnauthorized?: boolean;
  /**
   * Subdomain to add to the query string to avoid CORS issues with multiple subdomains
   *
   * Set to null to disable.
   *
   * @default GATSBY_SUBDOMAIN
   */
  subdomain?: string | null;
  kyOpts?: Options;
};

/**
 * Fetch data from backend with JWT authentication headers
 *
 * Use this with React Query.
 *
 * @returns The `data` property of the response unless `opts.fullResponse` is set to true
 * @throws {FetchError} instead of returning a response
 */
export const fetchBackend = async <
  TMethod extends FetchMethod,
  TResponse = unknown,
>(
  method: TMethod,
  endpoint: string,
  opts: FetchOptions<TMethod> = {},
) => {
  try {
    const res = (await fetchBackendCommon(
      endpoint,
      method,
      opts,
    )) as Promise<TResponse>;
    return res;
  } catch (error) {
    handleFetchError(error);
  }
  // This should never be reached, but TS requires a return value and React Query doesn't accept undefined
  return null as unknown as Promise<TResponse>;
};

/**
 * Fetch data from backend with JWT authentication headers
 *
 * Old version of fetchBackend, kept for backwards compatibility.
 *
 * Use this with React.useEffect.
 *
 * @returns The `data` property of the response unless `opts.fullResponse` is set to true.
 * In case of an error, returns the response with the error property set.
 */
export const fetchBackendEffect = async <TMethod extends FetchMethod>(
  endpoint: string,
  method: TMethod,
  json?: TMethod extends "get" ? undefined : unknown,
  defaultData?: object,
  opts?: Pick<FetchOptions<TMethod>, "fullResponse">,
) => {
  try {
    const res = await fetchBackendCommon(endpoint, method, { json, ...opts });
    return res;
  } catch (error) {
    return handleFetchErrorReturning({}, error, defaultData);
  }
};

const isProductionMainPlus =
  process.env.GATSBY_SUBDOMAIN === "plus" &&
  isBrowserEnvironment &&
  window.location.hostname === new URL(baseUrlProduction.plus).hostname;
/**
 * Append subdomain to the query string to avoid CORS issues with multiple subdomains
 *
 * Nothing gets appended in the main production Plus environment for cleaner URLs.
 */
const defaultSubdomainQuery = isProductionMainPlus
  ? null
  : `${process.env.GATSBY_SUBDOMAIN}_${
      isBrowserEnvironment ? window.location.hostname : "server"
    }`;

/**
 * Common fetch backend function
 *
 * Returns the `data` property of the response unless `opts.fullResponse` is set to true.
 */
const fetchBackendCommon = async <TMethod extends FetchMethod>(
  endpoint: string,
  method: TMethod,
  {
    fullResponse = false,
    allowUnauthorized = false,
    subdomain = defaultSubdomainQuery,
    ...opts
  }: FetchOptions<TMethod>,
) => {
  // Add subdomain to the query string to avoid CORS issues with multiple subdomains
  const url = new URL(endpoint);
  if (subdomain !== null) {
    url.searchParams.append("subdomain", subdomain);
  }
  endpoint = url.toString();

  const kyOpts: Options = {
    timeout: DEFAULT_FETCH_TIMEOUT_MS,
    ...opts.kyOpts,
  };

  const jwtRequest = createJwtRequest(getToken(!allowUnauthorized));

  const res = await match<FetchMethod>(method)
    .with("get", () => jwtRequest.get(endpoint, opts.kyOpts).json())
    .with("post", () =>
      jwtRequest.post(endpoint, { ...kyOpts, json: opts.json }).json(),
    )
    .with("put", () =>
      jwtRequest.put(endpoint, { ...kyOpts, json: opts.json }).json(),
    )
    .with("patch", () =>
      jwtRequest.patch(endpoint, { ...kyOpts, json: opts.json }).json(),
    )
    .with("delete", () =>
      jwtRequest.delete(endpoint, { ...kyOpts, json: opts.json }).json(),
    )
    .exhaustive();
  return fullResponse ? res : (get(res, "data") as unknown);
};

export function isKyHTTPError(error: unknown): error is HTTPError {
  return typeof error === "object" && error !== null && "response" in error;
}
