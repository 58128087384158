import React from "react";
import { Helmet } from "react-helmet";

import imgGdcoLogo from "../../assets/branded-gdco/gamediscoverco-logo.png";

const createDescriptionTag = (description: SeoProps["description"]) => {
  if (typeof description !== "string") return null;
  return [
    <meta id="description" name="description" content={description} key="k1" />,
    <meta
      id="twitter:description"
      name="twitter:description"
      content={description}
      key="k2"
    />,
    <meta
      id="og:description"
      property="og:description"
      content={description}
      key="k3"
    />,
  ];
};
const createCanonicalTag = (canonical: SeoProps["canonical"]) => {
  if (typeof canonical !== "string") return null;
  return [
    <link
      key="seo_canonical"
      id="canonical"
      rel="canonical"
      href={canonical}
    />,
    <meta key="seo_og:url" id="og:url" property="og:url" content={canonical} />,
  ];
};
const createImageTag = (image: SeoProps["image"]) => {
  if (typeof image !== "string") return null;
  return [
    <meta
      key="seo_og:image"
      id="og:image"
      property="og:image"
      content={image}
    />,
    <meta
      key="seo_twitter:image"
      id="twitter:image"
      name="twitter:image"
      content={image}
    />,
  ];
};
const createTwitterCardTag = (twitterCard: SeoProps["twitterCard"]) => {
  return typeof twitterCard === "string" ? (
    <meta
      key="seo_twitter:card"
      id="twitter:card"
      name="twitter:card"
      content={twitterCard}
    />
  ) : null;
};
const createTwitterSiteTag = (twitterSite: SeoProps["twitterSite"]) => {
  return typeof twitterSite === "string" ? (
    <meta
      key="seo_twitter:site"
      id="twitter:site"
      name="twitter:site"
      content={twitterSite}
    />
  ) : null;
};
const createTwitterCreatorTag = (
  twitterCreator: SeoProps["twitterCreator"],
) => {
  return typeof twitterCreator === "string" ? (
    <meta
      key="seo_twitter:creator"
      id="twitter:creator"
      name="twitter:creator"
      content={twitterCreator}
    />
  ) : null;
};
const createOgTypeTag = (ogType: SeoProps["ogType"]) => {
  return typeof ogType === "string" ? (
    <meta key="seo_og:type" id="og:type" property="og:type" content={ogType} />
  ) : null;
};
const createOgSiteNameTag = (ogSiteName: SeoProps["ogSiteName"]) => {
  return typeof ogSiteName === "string" ? (
    <meta
      key="seo_og:site_name"
      id="og:site_name"
      property="og:site_name"
      content={ogSiteName}
    />
  ) : null;
};

/**
 * Provides meta tags for each page
 */
const Seo = ({
  title = "GameDiscoverCo",
  description = null,
  canonical,
  image = imgGdcoLogo,
  twitterCard = "summary",
  twitterSite = "@gamediscoverco",
  twitterCreator = "@gamediscoverco",
  ogType = "website",
  ogSiteName = "GameDiscoverCo",
  isFragment = false,
}: SeoProps) => {
  const descriptionTag = createDescriptionTag(description);
  const canonicalTag = createCanonicalTag(canonical);
  const imageTag = createImageTag(image);
  const twitterCardTag = createTwitterCardTag(twitterCard);
  const twitterSiteTag = createTwitterSiteTag(twitterSite);
  const twitterCreatorTag = createTwitterCreatorTag(twitterCreator);
  const ogTypeTag = createOgTypeTag(ogType);
  const ogSiteNameTag = createOgSiteNameTag(ogSiteName);

  const metaTags = [
    <meta
      key="seo_twitter:title"
      id="twitter:title"
      name="twitter:title"
      content={title ?? ""}
    />,
    <meta
      key="seo_og:title"
      id="og:title"
      property="og:title"
      content={title ?? ""}
    />,
    descriptionTag,
    canonicalTag,
    imageTag,
    twitterCardTag,
    twitterSiteTag,
    twitterCreatorTag,
    ogTypeTag,
    ogSiteNameTag,
  ];

  if (isFragment) {
    return (
      <>
        <html lang="en" />
        {title && <title id="title">{title}</title>}
        {metaTags}
      </>
    );
  }

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={title ?? undefined}>
      {metaTags}
    </Helmet>
  );
};

export interface SeoProps {
  /** max 70 characters */
  title?: string | null;
  /** max 155 characters */
  description?: string | null;
  canonical?: string | null;
  /** twitter:image - min 144x144px, max 1MB, no SVG support */
  image?: string | null;
  twitterCard?: string | null;
  twitterSite?: string | null;
  twitterCreator?: string | null;
  ogType?: string | null;
  ogSiteName?: string | null;
  /**
   * If true, only the meta tags will be rendered (used for Gatsby Head component)
   * If false, the React Helmet component will be rendered.
   *
   * @default false
   */
  isFragment?: boolean;
}

export default Seo;
