/* eslint-disable unicorn/filename-case -- util file */

import { capitalize, isNumber } from "lodash";
import React from "react";
import { match, P } from "ts-pattern";

import type { PlatformKeyShort } from "../platforms";
import { platformList } from "../platforms";
import { th } from "../pro-theme";

import { gdcoLocale } from "@gdco/fe-core/util/locale";
import { centsToDollars, decimalToPct } from "@gdco/fe-core/util/math";

/*
 * From MDN:
 * "When formatting large numbers of numbers, it is better to create a Intl.NumberFormat object
 * and use the function provided by its format property."
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#performance
 */
export enum Fractions {
  None = 0,
  Two = 2,
  Four = 4,
}

const numberFormat = Intl.NumberFormat("en-US", {
  minimumFractionDigits: Fractions.None,
  maximumFractionDigits: Fractions.None,
});
const numberFractionsTwoFormat = Intl.NumberFormat("en-US", {
  minimumFractionDigits: Fractions.None,
  maximumFractionDigits: Fractions.Two,
});
const numberFractionsFourFormat = Intl.NumberFormat("en-US", {
  minimumFractionDigits: Fractions.None,
  maximumFractionDigits: Fractions.Four,
});
const priceFormat = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: Fractions.None,
  maximumFractionDigits: Fractions.None,
});
const priceFractionsFormat = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: Fractions.None,
  maximumFractionDigits: Fractions.Four,
});

/** Formats a number to a human-readable string */
export function formatNumber(value: number, opts?: RenderNumberOpts) {
  switch (opts?.fractions) {
    case false:
    case Fractions.None: {
      return numberFormat.format(value);
    }
    case Fractions.Four: {
      return numberFractionsFourFormat.format(value);
    }
    default: {
      return numberFractionsTwoFormat.format(value);
    }
  }
}

export function formatNumberOrNA(value: unknown, opts?: RenderNumberOpts) {
  if (!isNumber(value)) {
    return gdcoLocale.na;
  }
  return formatNumber(value, opts);
}

export type RenderNumberOpts = {
  /**
   * Number of fraction digits to show.
   *
   * @default 2
   */
  fractions?: false | Fractions;
};

/**
 * Abbreviates a number to a human-readable string
 *
 * Accepts only positive numbers.
 */
export function abbreviateNumberUnsigned(n: number | null, fraction = 1) {
  if (n === null) return gdcoLocale.na;

  /* eslint-disable @typescript-eslint/no-magic-numbers -- we're doing math! */
  // always show one fraction digit if n[1000..10000]
  if (n >= 1e3 && n < 1e4) return `${+(n / 1e3).toFixed(1)}k`;
  if (n >= 1e4 && n < 1e6) return `${+(n / 1e3).toFixed(fraction)}k`;
  if (n >= 1e6 && n < 1e7) return `${+(n / 1e6).toFixed(1)}M`;
  if (n >= 1e7 && n < 1e9) return `${+(n / 1e6).toFixed(fraction)}M`;
  if (n >= 1e9 && n < 1e10) return `${+(n / 1e9).toFixed(1)}B`;
  if (n >= 1e10 && n < 1e12) return `${+(n / 1e9).toFixed(fraction)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(fraction)}T`;
  return `${n}`;
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}

/**
 * Abbreviates a number to a human-readable string
 *
 * Accepts both positive and negative numbers.
 */
export function abbreviateNumber(n: number | null, fraction = 1) {
  if (n === null) return gdcoLocale.na;
  if (n < 0) return `-${abbreviateNumberUnsigned(-n, fraction)}`;
  return abbreviateNumberUnsigned(n, fraction);
}

export function rankNumber(n: number) {
  if (n === 0) return gdcoLocale.na;
  return `#${n}`;
}

/**
 * Formats a price to a human-readable string
 *
 * Without fractions by default.
 */
export function formatPrice(price: number, opts?: RenderPriceOpts) {
  if (opts?.fractions === true) {
    return priceFractionsFormat.format(price);
  }
  return priceFormat.format(price);
}

export function formatPriceOrNA(
  price: number | null | undefined,
  opts?: RenderPriceOpts,
) {
  if (price === null || price === undefined) {
    return gdcoLocale.na;
  }
  return formatPrice(price, opts);
}

/**
 * Formats a base and current price to a human-readable string
 */
export function formatPrices(
  priceBase: number | null,
  priceCurrent: number | null,
  isFree: boolean,
  isCents = true,
  opts?: RenderPriceOpts,
) {
  return match([priceBase, priceCurrent, isFree])
    .with([null, null, false], () => gdcoLocale.na)
    .with([null, null, true], () => "Free")
    .with([P.number, P.number, false], ([base, current]) => {
      base = isCents ? centsToDollars(base) : base;
      current = isCents ? centsToDollars(current) : current;

      if (base === current) {
        return formatPrice(base, opts);
      }
      return (
        <>
          <s>{formatPrice(base, opts)}</s> {formatPrice(current, opts)}
        </>
      );
    })
    .otherwise(() => gdcoLocale.na);
}

type RenderPriceOpts = {
  /** @default false */
  fractions?: boolean;
};

export function stringToNumber(value: unknown): number {
  if (typeof value !== "string") {
    return Number.NaN;
  }
  return Number(value.replaceAll(",", "").replaceAll("%", ""));
}

export function percentStringToNumber(value: unknown): number {
  if (typeof value !== "string") {
    return Number.NaN;
  }
  return decimalToPct(Number(value.replaceAll(",", "").replaceAll("%", "")));
}

/**
 * Formats a rank change to a human-readable string and color
 */
export function formatRankChange(
  value: number | null | undefined,
  formattedValue?: string,
  emptyText = "new",
) {
  let text: string;
  let color: string;
  if (typeof value === "number") {
    formattedValue ??= formatNumber(value);
    if (value === 0) {
      text = `= ${formattedValue}`;
      color = th.steamReviewMixedLight;
    } else if (value < 0) {
      text = `▼ ${formattedValue}`;
      color = th.steamReviewNegative;
    } else {
      text = `▲ ${formattedValue}`;
      color = th.positiveGreen;
    }
  } else {
    text = emptyText;
    color = th.steamReviewPositiveLight;
  }

  return { text, color };
}

/**
 * Formats subscription details to a human-readable string
 */
export function getSubscriptionDetails<
  TPlatform extends PlatformKeyShort,
  TRow extends Partial<
    Record<
      `playerPercentSub${TPlatform}` | `playerPercentNoSub${TPlatform}`,
      number | null
    >
  >,
>(platform: TPlatform, row: TRow) {
  if (platform === platformList.St.id) {
    return null;
  }
  const p = platform as Exclude<TPlatform, "St">;

  const pctSubscription = row[`playerPercentSub${p}`] ?? 0;
  const pctNoSubscription = row[`playerPercentNoSub${p}`] ?? 1;
  if (pctSubscription > 0) {
    return (
      <>
        ({formatNumber(decimalToPct(pctSubscription ?? 0))}%{" "}
        {platformList[p].subscription.shortName} /{" "}
        {formatNumber(decimalToPct(pctNoSubscription))}% paid)
      </>
    );
  }
  return null;
}

export function formatNearestNumber(
  value: number | null,
  nearest: number,
  opts: RenderNearestNumberOpts = {},
) {
  let result: { roundedValue: number | null; text: string };
  if (value === null) {
    result = { roundedValue: null, text: gdcoLocale.na };
  } else {
    const roundedValue = Math.round(value / nearest) * nearest;
    let text: string;
    if (value < nearest) {
      text = opts.hideLowValue ? `<${nearest}` : formatNumber(value);
    } else {
      text = formatNumber(roundedValue);
    }
    result = {
      roundedValue,
      text,
    };
  }

  return result;
}

export type RenderNearestNumberOpts = {
  /** If true, returns "<nearest" instead of the formatted number. */
  hideLowValue?: boolean;
};

/**
 * Get the full language name from the language code
 *
 * @see https://partner.steamgames.com/doc/store/localization/languages
 */
export function getLanguageName(languageCode: string) {
  return match(languageCode)
    .with("schinese", () => "Chinese (Simplified)")
    .with("tchinese", () => "Chinese (Traditional)")
    .with("koreana", () => "Korean")
    .with("brazilian", () => "Portuguese-Brazil")
    .with("latam", () => "Spanish-Latin America")
    .with("spanish", () => "Spanish-Spain")
    .otherwise(() => capitalize(languageCode));
}

/* eslint-enable unicorn/filename-case */
