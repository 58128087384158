import { styled } from "@mui/material";
import type { CSSProperties, ReactNode } from "react";
import React from "react";

/** Acts as a container for laying out content in a column */
const LayoutColumn = ({
  children,
  htmlTag = "main",
  alignItems = "center",
  justifyContent = "center",
  textAlign = "inherit",
  paddingTop = 0,
  paddingBottom = 0,
  sidePadding = 0,
}: LayoutColumnProps) => {
  return (
    <ContentRestrictWidth
      as={htmlTag}
      columnCss={{
        alignItems,
        justifyContent,
        textAlign,
        maxWidth: "100%",
        width: "100%",
        height: "auto",
        paddingTop,
        paddingBottom,
        sidePadding,
      }}
    >
      {children}
    </ContentRestrictWidth>
  );
};

type LayoutColumnProps = {
  children: ReactNode;
  htmlTag?: keyof JSX.IntrinsicElements;
} & LayoutColumnCssProps;

type LayoutColumnCssProps = {
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
  textAlign?: CSSProperties["textAlign"];
  /** Max width of the content. If a number or a number string is provided, it will be interpreted as a rem value. */
  maxWidth?: CSSProperties["maxWidth"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  paddingTop?: CSSProperties["paddingTop"];
  paddingBottom?: CSSProperties["paddingBottom"];
  sidePadding?: CSSProperties["padding"];
};

export default LayoutColumn;

const ContentRestrictWidth = styled("div")<{
  columnCss: Required<LayoutColumnCssProps>;
}>`
  padding-top: ${(props) => props.columnCss.paddingTop}rem;
  padding-right: ${(props) => props.columnCss.sidePadding}rem;
  padding-left: ${(props) => props.columnCss.sidePadding}rem;
  padding-bottom: ${(props) => props.columnCss.paddingBottom}rem;
  max-width: ${(props) =>
    `min(100%, ${
      Number.isFinite(Number(props.columnCss.maxWidth))
        ? `${props.columnCss.maxWidth}rem`
        : props.columnCss.maxWidth
    })`};
  width: ${(props) => props.columnCss.width};
  height: ${(props) => props.columnCss.height};
  text-align: ${(props) => props.columnCss.textAlign};
  margin: 0 auto;

  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  align-items: ${(props) => props.columnCss.alignItems};
  justify-content: ${(props) => props.columnCss.justifyContent};
`;
