import { Cached, Home } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Grid, Typography } from "@mui/material";
import { getReasonPhrase } from "http-status-codes";
import type { ReactNode } from "react";
import React from "react";

import HeaderHero from "../layout/HeaderHero";
import LayoutColumn from "../layout/LayoutColumn";

import { FetchError } from "@gdco/fe-core/fetch/fetch-error";
import { routeInternal as routes } from "@gdco/fe-core/fetch/route-internal";

const browser = typeof window !== "undefined" && window;

const ErrorContent = ({
  heading,
  subheading,
  options,
  error,
}: ErrorContentProps) => {
  if (!browser) return null;

  let optionalUi: ReactNode = null;
  if (options) {
    optionalUi = (
      <>
        {options.map((el, i) => {
          return (
            <Typography variant="body1" key={i} paragraph>
              <span>{el.caption}</span>
              {el.email && <a href={"mailto:" + el.email}>{el.email}</a>}
              {"link" in el && <a href={el.link}>{el.linkLabel}</a>}
            </Typography>
          );
        })}
      </>
    );
  }

  return (
    <LayoutColumn>
      <HeaderHero />
      <div style={{ marginTop: "3rem" }} />
      {error instanceof FetchError ? (
        <Alert severity="error" css={{ marginBottom: "2rem" }}>
          <AlertTitle>Failed to load data</AlertTitle>
          {Number.isInteger(Number(error.code)) && (
            <>
              Error {error.code}: {getReasonPhrase(Number(error.code))}
              <br />
              <br />
            </>
          )}
          {error.message}
        </Alert>
      ) : (
        <>
          <Typography variant="h4">{heading}</Typography>
          <Typography variant="h6" paragraph>
            {subheading}
          </Typography>
        </>
      )}
      {optionalUi}
      <br />
      <br />
      <Grid container gap={2} justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Cached />}
          href="."
          onClick={() => window.location.reload()}
        >
          Reload Page
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Home />}
          href={routes.home.to}
          // do NOT use GatsbyLink: if the error happens at route home, the error page will still show without any change
        >
          Return to Start
        </Button>
      </Grid>
    </LayoutColumn>
  );
};

export type ErrorContentProps = {
  heading: string;
  subheading: string;
  options?: ErrorOption[];
  error?: unknown;
};

type ErrorOptionBase = {
  caption: ReactNode;
  email?: string;
};

export type ErrorOption =
  | ErrorOptionBase
  | (ErrorOptionBase & {
      link: string;
      linkLabel: ReactNode;
    });

export default ErrorContent;
