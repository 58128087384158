import { storageFactory } from "storage-factory";
import { z } from "zod";

import { ROLE_LIST } from "../roles";

import { isBrowserEnvironment, ProNavigationState } from "./config";

export let storageInitialized = false;

/**
 * Initialize this file before the rest of the app
 *
 * This file accesses other files in the app where the storage is used so it needs to be initialized first.
 */
export function initStorage() {
  if (storageInitialized) {
    return;
  }
  storageInitialized = true;
}

/**
 * Name of the custom event that is dispatched when a value in local storage changes in the current tab
 */
export const LOCAL_STORAGE_EVENT_CURRENT_TAB = "local-storage";

/**
 * Name of the custom event that is dispatched when a value in local storage changes in other tabs
 */
export const LOCAL_STORAGE_EVENT_OTHER_TAB =
  "storage" satisfies keyof WindowEventMap;

/** Local storage keys and validation schemas */
export const gdcoStorageData = {
  /** User token */
  userToken: {
    keyName: "token" as const,
    parseJSON: false,
    schema: z.string().nullable().default(null),
    default: null,
  },
  /** User info */
  userInfo: {
    keyName: "user_info" as const,
    parseJSON: true,
    schema: z
      .object({
        uid: z.string().default(""),
        email: z.string().nullable().default(null),
        displayName: z.string().nullable().default(null),
        photoURL: z.string().nullable().default(null),
        emailVerified: z.boolean().default(false),
        metadata: z.object({
          creationTime: z.string().optional(),
          lastSignInTime: z.string().optional(),
        }),
        roles: z.array(z.enum(ROLE_LIST)).default([]),
        /** Fake roles are used to display the user as having roles that they don't actually have */
        fakeRoles: z.array(z.enum(ROLE_LIST)).optional(),
      })
      .nullable(),
    default: null,
  },
  /** Sidebar's open/closed state */
  navigationState: {
    keyName: "nav_state" as const,
    parseJSON: false,
    schema: z
      .preprocess(
        Number,
        z.union([
          z.literal(ProNavigationState.FULL),
          z.literal(ProNavigationState.SMALL),
        ]),
      )
      .default(ProNavigationState.FULL),
    default: ProNavigationState.FULL,
  },
  /** Color mode */
  colorMode: {
    keyName: "color_mode" as const,
    parseJSON: false,
    schema: z
      .union([z.literal("system"), z.literal("light"), z.literal("dark")])
      .default("system"),
    default: "system",
  },
  /** List of app IDs to compare */
  appCompare: {
    keyName: "app_compare" as const,
    parseJSON: true,
    schema: z.array(z.number()).or(z.null()).default(null),
    default: null,
  },
  /** Count of 429 errors */
  tooManyRequests: {
    keyName: "too_many_requests" as const,
    parseJSON: true,
    schema: z.object({
      count: z.number().int().nonnegative().default(0),
      timestamp: z.number().int().nonnegative().default(0),
    }),
    default: { count: 0, timestamp: 0 },
  },
} satisfies Record<string, StorageData>;

type StorageData = {
  /** Key name in local storage (without the `gdco_` prefix and the subdomain) */
  keyName: string;
  /** Whether to parse the value as JSON or get it as a string */
  parseJSON: boolean;
  /** Zod validation schema */
  schema: z.ZodType;
  /** Default value in case of a validation error */
  default: unknown;
};

/**
 * Use this for direct access to local storage
 *
 * Using localStorage directly is not recommended because it can throw exceptions in some cases.
 *
 * @see https://github.com/MichalZalecki/storage-factory
 * @see https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 */
// eslint-disable-next-line no-restricted-globals -- localStorage wrapper
const gdcoLocalStorageFactory = storageFactory(() => localStorage);

/**
 * Use this for direct access to session storage
 *
 * Using sessionStorage directly is not recommended because it can throw exceptions in some cases.
 *
 * @see https://github.com/MichalZalecki/storage-factory
 * @see https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 */
// eslint-disable-next-line no-restricted-globals -- sessionStorage wrapper
const gdcoSessionStorageFactory = storageFactory(() => sessionStorage);

/**
 * Use this to access local and session storage in a type-safe way
 */
export const gdcoStorage = {
  local: {
    ...gdcoLocalStorageFactory,
    /**
     * Gets a value from local storage and parses it
     *
     * @returns The parsed value or the default value
     */
    getItem: <TKey extends LocalStorageDataKey>(
      key: TKey,
      initialValue: LocalStorageValue<TKey> = gdcoStorageData[key].default,
    ): LocalStorageValue<TKey> => {
      const localStorageKey = getStorageKeyName(gdcoStorageData[key].keyName);
      const schema = gdcoStorageData[key].schema;
      const defaultValue = initialValue ?? gdcoStorageData[key].default;
      const value = isBrowserEnvironment
        ? gdcoLocalStorageFactory.getItem(localStorageKey)
        : null;

      let transformedValue: unknown;

      if (value === null) {
        // No value in local storage, use the default value
        return defaultValue;
      }

      // Some values are simple strings, some are stringified JSON
      if (gdcoStorageData[key].parseJSON) {
        try {
          transformedValue = JSON.parse(value);
        } catch {
          transformedValue = value;
        }
      } else {
        transformedValue = value;
      }

      const parsed = schema.safeParse(transformedValue);

      if (!parsed.success) {
        gdcoLocalStorageFactory.removeItem(localStorageKey);
        return defaultValue;
      }

      return parsed.data;
    },

    /**
     * Stores a value in local storage and dispatches a custom event to notify the current tab about the change
     */
    setItem: <TKey extends LocalStorageDataKey>(
      key: TKey,
      value: LocalStorageValue<TKey>,
      dispatchEvent = true,
    ) => {
      if (!isBrowserEnvironment) {
        return;
      }

      const localStorageKey = getStorageKeyName(gdcoStorageData[key].keyName);
      const storageValue = gdcoStorageData[key].parseJSON
        ? JSON.stringify(value)
        : String(value);
      gdcoLocalStorageFactory.setItem(localStorageKey, storageValue);

      if (dispatchEvent) {
        window.dispatchEvent(
          new CustomEvent(LOCAL_STORAGE_EVENT_CURRENT_TAB, {
            detail: { key: localStorageKey, value },
          } as LocalStorageCurrentTabEvent),
        );
      }
    },

    /**
     * Removes a value from local storage and dispatches a custom event to notify the current tab about the change
     */
    removeItem: (key: LocalStorageDataKey, dispatchEvent = true) => {
      if (!isBrowserEnvironment) {
        return;
      }

      const localStorageKey = getStorageKeyName(gdcoStorageData[key].keyName);
      const defaultValue = gdcoStorageData[key].default;
      gdcoLocalStorageFactory.removeItem(localStorageKey);

      if (dispatchEvent) {
        window.dispatchEvent(
          new CustomEvent(LOCAL_STORAGE_EVENT_CURRENT_TAB, {
            detail: { key: localStorageKey, value: defaultValue },
          }) as CustomEvent,
        );
      }
    },
  },
  session: gdcoSessionStorageFactory,
};

/**
 * Async version of {@link gdcoStorage}, used for Jotai atoms
 */
export const gdcoStorageAsync = {
  local: {
    getItem: async <TKey extends LocalStorageDataKey>(
      key: TKey,
      initialValue: LocalStorageValue<TKey> = gdcoStorageData[key].default,
    ): Promise<LocalStorageValue<TKey>> => {
      const promise = new Promise<LocalStorageValue<TKey>>((resolve) => {
        const data = gdcoStorage.local.getItem(key, initialValue);
        resolve(data);
      });
      return promise;
    },
    setItem: async <TKey extends LocalStorageDataKey>(
      key: TKey,
      value: LocalStorageValue<TKey>,
      dispatchEvent = true,
    ): Promise<void> => {
      const promise = new Promise<void>((resolve) => {
        gdcoStorage.local.setItem(key, value, dispatchEvent);
        resolve();
      });
      return promise;
    },
    removeItem: async (
      key: LocalStorageDataKey,
      dispatchEvent = true,
    ): Promise<void> => {
      const promise = new Promise<void>((resolve) => {
        gdcoStorage.local.removeItem(key, dispatchEvent);
        resolve();
      });
      return promise;
    },
  },
};

/**
 * Gets a key name for local storage, prefixed with `gdco_` and the subdomain
 */
function getStorageKeyName<TKey extends LocalStorageDataKey>(
  storageKey: (typeof gdcoStorageData)[TKey]["keyName"],
) {
  return `gdco_${process.env.GATSBY_SUBDOMAIN}_${storageKey}` as const;
}

/**
 * Gets a key name for local storage, prefixed with `gdco_` and the subdomain
 */
export function getStorageKey<TKey extends LocalStorageDataKey>(key: TKey) {
  return getStorageKeyName(gdcoStorageData[key].keyName);
}

/**
 * Internal key names for local storage
 *
 * Note: It isn't the real key name prefixed with `gdco_` and the subdomain.
 */
export type LocalStorageDataKey = keyof typeof gdcoStorageData;

/**
 * Inferred schema type for a key in local storage
 */
export type LocalStorageValue<TKey extends LocalStorageDataKey> = z.infer<
  (typeof gdcoStorageData)[TKey]["schema"]
>;

/**
 * List of all possible keys in local storage
 *
 * Not all of them are guaranteed to exist.
 */
type LocalStorageKeyList = ReturnType<
  typeof getStorageKeyName<LocalStorageDataKey>
>;

/**
 * Custom event that is dispatched when a value in local storage changes in the current tab
 */
export type LocalStorageCurrentTabEvent = CustomEvent<{
  key: LocalStorageKeyList;
  value: LocalStorageValue<LocalStorageDataKey>;
}>;

/** Session storage keys */
export const gdcoSessionStorageData = {
  /** side panel scroll pos */
  sidePanelScrollTop: {
    keyName: "gdco_sidepanel_scroll_top",
  },
};
