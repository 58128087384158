import type { PaletteMode } from "@mui/material";
import { noop } from "lodash";
import type { ReactNode } from "react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useEventListener, useIsClient } from "usehooks-ts";

import {
  gdcoStorage,
  getStorageKey,
  LOCAL_STORAGE_EVENT_OTHER_TAB,
} from "@gdco/fe-core/util/storage";

/** The color mode (light, dark, or system) */
export type ColorMode = PaletteMode | "system";

const allPaletteModes = new Set<PaletteMode>(["light", "dark"]);

export const ColorModeContext = createContext<ColorModeProviderContext>({
  colorMode: "system",
  paletteMode: "light",
  setColorMode: noop,
});

type ColorModeProviderContext = {
  /** The current color mode (including system) */
  colorMode: ColorMode;
  /** The current MUI palette mode (light or dark) */
  paletteMode: PaletteMode;
  /** Set the color mode */
  setColorMode: (colorMode: ColorMode) => void;
};

export function useColorMode() {
  return useContext(ColorModeContext);
}

export function ColorModeProvider(props: ColorModeProviderProps) {
  const isClient = useIsClient();

  const [prefersDarkMode, setPrefersDarkMode] = useState(false);
  const [colorMode, setColorMode] = useState<ColorMode>("system");
  const [paletteMode, setPaletteMode] = useState<PaletteMode>(() => "light");

  useEffect(() => {
    // Initialize the color mode from local storage, only on the client to avoid hydration mismatch

    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches;
    const storedColorMode = gdcoStorage.local.getItem("colorMode");
    setPrefersDarkMode(prefersDarkMode);
    setColorMode(storedColorMode);
    setPaletteMode(
      storedColorMode === "system"
        ? prefersDarkMode
          ? "dark"
          : "light"
        : storedColorMode,
    );
  }, [isClient]);

  useEventListener(LOCAL_STORAGE_EVENT_OTHER_TAB, (event) => {
    // Update the color mode when it changes in another tab
    if (event.key === getStorageKey("colorMode")) {
      if (event.newValue === null) {
        setColorMode("system");
        setPaletteMode(prefersDarkMode ? "dark" : "light");
      } else if (allPaletteModes.has(event.newValue as PaletteMode)) {
        setColorMode(event.newValue as ColorMode);
        setPaletteMode(event.newValue as PaletteMode);
      }
    }
  });

  const handleColorModeChange = useCallback(
    (colorMode: ColorMode) => {
      setColorMode(colorMode);
      if (colorMode === "system") {
        gdcoStorage.local.removeItem("colorMode");
        setPaletteMode(prefersDarkMode ? "dark" : "light");
      } else {
        gdcoStorage.local.setItem("colorMode", colorMode);
        setPaletteMode(colorMode);
      }
    },
    [prefersDarkMode],
  );

  useEffect(() => {
    // Watch for changes to prefers-color-scheme

    function handleSystemColorSchemeChange(ev: MediaQueryListEvent) {
      setPrefersDarkMode(ev.matches);
      if (colorMode === "system") {
        setPaletteMode(ev.matches ? "dark" : "light");
      }
    }

    const mediaWatcher = window.matchMedia("(prefers-color-scheme: dark)");
    mediaWatcher.addEventListener("change", handleSystemColorSchemeChange);

    return () => {
      mediaWatcher.removeEventListener("change", handleSystemColorSchemeChange);
    };
  }, [colorMode, handleColorModeChange]);

  return (
    <ColorModeContext.Provider
      value={{
        colorMode,
        paletteMode,
        setColorMode: handleColorModeChange,
      }}
    >
      {props.children}
    </ColorModeContext.Provider>
  );
}

type ColorModeProviderProps = {
  children: ReactNode;
};
