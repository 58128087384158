/* eslint-disable @typescript-eslint/no-magic-numbers -- theme constants */

import type {
  CustomTheme,
  PaletteMode,
  PaletteOptions,
  ThemeOptions,
} from "@mui/material";
import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import type { Breakpoint } from "@mui/system";
import { alpha, darken, lighten } from "@mui/system";

const theme1 = {
  /** --- new theme constants --- **/
  titleBg: "#214b6b",
  titleFg: "#eeeeee",
  sidebarBg: "#111111",
  sectionHeaderBg: "#214b6b",
  sectionHeaderFg: "#ffffff",
  footerBg: "#214b6b",
  imageShadow:
    "0px 2px 4px -1px rgba(0,0,20,0.1), 0px 4px 5px 0px rgba(0,0,20,0.1), 0px 1px 10px 0px rgba(0,0,20,0.1)",
  imageBorderRadius: "4px",
  stdPadding: "15px",
  stdMarginBottom: "15px",
  paper: "#e9f3ff",
  primary: {
    light: "#517799",
    main: "#214b6b",
    dark: "#002440",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#c0c0c0", //th.newspaperGrayLight,
    main: "#f3f3f3",
    dark: "#909090",
    contrastText: "#1f1f1f",
  },
};

const currentTheme = theme1;

export const th = {
  ...currentTheme,

  /** --- gdco theme constants --- **/
  textColorOnLight: "#1f1f1f",
  textErrorMain: "#f44336", // -> theme.palette.error.main
  textMuted: "rgba(0, 0, 0, 0.38)", // -> MUI default disabled
  textMutedMoreContrast: "#616c76",
  textNewspaper: "#3f444d",
  linkBlue: "#1000ff",
  linkVisitedPurple: "#653096",
  linkHighlightedPurple: "#a927ff",
  linkOrange: "#ff580c", // more saturated than dotemuOrange
  background: "#f3faff",
  paper: currentTheme.paper,
  backgroundHypeChart: "rgba(73, 92, 116, 0.25)",
  backgroundHypeChartCss: "rgba(96, 125, 164, 0.3)",
  positiveGreen: "#098911",

  /** color palettes */
  steamBlueLight: "#517799",
  steamBlue: "#214b6b",
  steamBlueDark: "#002440",
  steamReviewPositive: "#66c0f4",
  steamReviewPositiveLight: "#0071b3", // light-themed variant
  steamReviewMixed: "#b9a074",
  steamReviewMixedLight: "#9f7427", // light-themed variant
  steamReviewNegative: "#a34c25",
  steamReviewNegativeLight: "#b71c01", // light-themed variant
  gameboyGreenLight: "#5c937c",
  gameboyGreen: "#2f6550",
  gameboyGreenDark: "#003a28",
  dotemuOrangeLight: "#ffb06d",
  dotemuOrange: "#fc7f3f",
  dotemuOrangeDark: "#c3500f",
  newspaperGrayLight: "#c0c0c0", // darker than gray -> needs fix
  newspaperGray: "#f3f3f3", // brighter than lightgray -> needs fix
  newspaperGrayDark: "#909090",

  /** brand colors */
  discordBlue: "#7289da",
  facebookBlue: "#395693",
  instagramBlack: "#000000",
  redditOrange: "#ff4500",
  twitterBlue: "#1da1f2",
  youTubeRed: "#f60000",
  gitHubBlack: "#000000",
  twitchPurple: "#8c44f7",
  xboxGreen: "#0e7a0d",
  playstationBlueDark: "#003791",

  /** chart colors */
  chartColor: [
    "#214b6b",
    "#355085",
    "#5e4f96",
    "#8f469a",
    "#be338d",
    "#e41770",
    "#fb1a47",
    "#ff4500",
    "#616c76",
    "#003a28",
  ],

  /** Site constants **/
  widthContent: 70,
  widthContentNarrow: 32,
  widthMobileMin: 20,
  widthTable: 105,
  widthText: 44,
  spacingVertical: 3,
  spacingVerticalNarrower: 2.5,
  spacingVerticalNarrow: 1.5,
  spacingVerticalSmol: 1.2,
  spacingHorizontal: 0.5,
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  breakpoints: {
    // When adding a new breakpoint, make sure to add its name to the `mui.d.ts` file
    // Values are in px
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    } as Record<Breakpoint, number>,
  },

  /** Reused strings */
  na: "N/A",
};

export function getProTheme(mode: PaletteMode) {
  const primaryColor = mode === "light" ? "#214b6b" : "rgb(105, 125, 236)";
  const navMainColor = mode === "light" ? "#214b6b" : "#21266b";
  const paperBgColor = mode === "light" ? "#ebf5ff" : "#121212";
  const tooltip: CustomTheme["tooltip"] = {
    backgroundColor: darken(paperBgColor, 0.1),
    color: mode === "light" ? "#1f1f1f" : "#ffffff",
    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
  };

  const gridBgOpacity = {
    normal: 0.1,
    hover: 0.2,
    selected: 0.3,
  };

  const palette: PaletteOptions = {
    mode,
    background: {
      paper: paperBgColor,
      default: mode === "light" ? "#f3faff" : "#121212",
    },
    primary: {
      main: primaryColor,
    },
    nav: {
      main: navMainColor,
      contrastText: "#ffffff",
    },
    secondary: {
      main: mode === "light" ? "#41216b" : "#f0e5f2",
    },
    text: {
      disabled: mode === "light" ? "#6d6d6d" : grey[500],
    },
    svgIcon: {
      main: mode === "light" ? "#000000" : "#ffffff",
    },
  };

  /** Create a theme just with the palette so we can use calculated palette colors in other theme options */
  const paletteTheme = createTheme({
    palette,
  } as ThemeOptions);

  const dataGridHeaderBgColor = mode === "light" ? "#c7d5e3" : "#17182d";

  const dataGrid = {
    cellBorderBottom: "none",
    even: {
      backgroundColor: alpha(
        paletteTheme.palette.primary.dark,
        gridBgOpacity.normal,
      ),
      hoverBackgroundColor: alpha(
        paletteTheme.palette.primary.light,
        gridBgOpacity.hover,
      ),
      selectedBackgroundColor: alpha(
        paletteTheme.palette.primary.dark,
        gridBgOpacity.selected,
      ),
      selectedHoverBackgroundColor: alpha(
        paletteTheme.palette.primary.main,
        gridBgOpacity.selected + gridBgOpacity.hover,
      ),
    },
    odd: {
      backgroundColor: alpha(
        mode === "light"
          ? paletteTheme.palette.primary.light
          : paletteTheme.palette.primary.main,
        gridBgOpacity.normal,
      ),
      hoverBackgroundColor: alpha(
        paletteTheme.palette.primary.light,
        gridBgOpacity.hover,
      ),
      selectedBackgroundColor: alpha(
        paletteTheme.palette.primary.main,
        gridBgOpacity.selected,
      ),
      selectedHoverBackgroundColor: alpha(
        paletteTheme.palette.primary.main,
        gridBgOpacity.selected + gridBgOpacity.hover,
      ),
    },
  };

  const theme = createTheme({
    palette,
    tooltip,
    dataGrid,
    typography: {
      allVariants: {
        color: mode === "light" ? "#1f1f1f" : "#ffffff",
      },
    },
    breakpoints: {
      // When adding a new breakpoint, make sure to add its name to the `mui.d.ts` file
      // Values are in px
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          ".link": {
            color: primaryColor,
            "&:visited": {
              color:
                mode === "light"
                  ? lighten(primaryColor, 0.3)
                  : darken(primaryColor, 0.3),
            },
            "&:hover": {
              color: "#ff580c",
            },
          },
          ".recharts-default-tooltip": {
            backgroundColor: `${tooltip.backgroundColor} !important`,
            color: `${tooltip.color} !important`,
            boxShadow: `${tooltip.boxShadow} !important`,
            border: `${paletteTheme.palette.divider} solid 1px !important`,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          main: {
            overflow: "visible",
          },
          columnHeader: {
            backgroundColor: dataGridHeaderBgColor,
          },
          pinnedColumns: {
            backgroundImage:
              mode === "light"
                ? "linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3))"
                : "linear-gradient(rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02))",
          },
          panelWrapper: {
            backgroundColor:
              mode === "light" ? "inherit" : darken(primaryColor, 0.9),
          },
          cell: {
            borderBottom: "none",
          },
          row: {
            "--rowBorderColor": "transparent",
            backgroundColor: dataGrid.even.backgroundColor,
            "&:hover": {
              backgroundColor: dataGrid.even.hoverBackgroundColor,
            },
            "&.Mui-selected": {
              backgroundColor: dataGrid.even.selectedBackgroundColor,
              "&:hover": {
                backgroundColor: dataGrid.even.selectedHoverBackgroundColor,
              },
            },
            "&:nth-of-type(odd)": {
              backgroundColor: dataGrid.odd.backgroundColor,
              "&:hover": {
                backgroundColor: dataGrid.odd.hoverBackgroundColor,
              },
              "&.Mui-selected": {
                backgroundColor: dataGrid.odd.selectedBackgroundColor,
                "&:hover": {
                  backgroundColor: dataGrid.odd.selectedHoverBackgroundColor,
                },
              },
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: "primary",
        },
        styleOverrides: {
          root: {
            paddingLeft: "8px",
            paddingRight: "8px",
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiChip: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiTableCell: {
        styleOverrides: {
          stickyHeader: {
            backgroundColor: mode === "light" ? "#e9f3ff" : "#121212",
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          tooltip,
          arrow: {
            color: tooltip.backgroundColor,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: paperBgColor,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: paperBgColor,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: paperBgColor,
          },
        },
      },
    },
  });

  return theme;
}

/* eslint-enable @typescript-eslint/no-magic-numbers */
