import { Location } from "@gatsbyjs/reach-router";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "jotai";
import { SnackbarProvider } from "notistack";
import queryString from "query-string";
import type { ReactNode } from "react";
import React from "react";
import { Helmet } from "react-helmet";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "use-query-params/adapters/reach";

import {
  ColorModeContext,
  ColorModeProvider,
} from "../../../context/ColorModeContext";
import { FavoriteAppsProvider } from "../../../context/FavoriteAppsContext";
import { HighchartsProvider } from "../../../context/HighchartsContext";
import ErrorBoundary from "../error/ErrorBoundary";
import { getProTheme } from "../pro-theme";

import CommandKMenuProvider from "./CommandKMenu";

import favicon from "@gdco/fe-core/assets/branded-gdco/gamediscoverco-logo.png";
import { UserProvider } from "@gdco/fe-core/context/UserContext";
import { reactQueryClient } from "@gdco/fe-core/fetch/QueryClient";
import { initStorage } from "@gdco/fe-core/util/storage";

import "./reset.css";
import "../style.css";

if (typeof process.env.GATSBY_XGRID_LICENSE_KEY === "string") {
  LicenseInfo.setLicenseKey(process.env.GATSBY_XGRID_LICENSE_KEY);
}

initStorage();

export default function RootLayout({ children }: RootLayoutProps) {
  return (
    <Provider>
      <QueryClientProvider client={reactQueryClient}>
        <Helmet>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <link rel="icon" href={favicon} />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
        </Helmet>
        <ErrorBoundary>
          <ColorModeProvider>
            <ColorModeContext.Consumer>
              {({ paletteMode }) => {
                const theme = getProTheme(paletteMode);

                return (
                  <ThemeProvider theme={theme} key={paletteMode}>
                    <CssBaseline enableColorScheme />
                    <Location>
                      {() => <AppProviders>{children}</AppProviders>}
                    </Location>
                  </ThemeProvider>
                );
              }}
            </ColorModeContext.Consumer>
          </ColorModeProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    </Provider>
  );
}

type RootLayoutProps = { children: ReactNode };

const AppProviders = (props: ChildrenProps) => {
  return (
    <ErrorBoundary>
      <QueryParamProvider
        adapter={ReachAdapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify,
        }}
      >
        <SnackbarProvider>
          <UserProvider>
            <HighchartsProvider>
              <FavoriteAppsProvider>
                <CommandKMenuProvider>{props.children}</CommandKMenuProvider>
              </FavoriteAppsProvider>
            </HighchartsProvider>
          </UserProvider>
        </SnackbarProvider>
      </QueryParamProvider>
    </ErrorBoundary>
  );
};

type ChildrenProps = { children: ReactNode };
