import { DensityMedium } from "@mui/icons-material";
import { Grid, styled } from "@mui/material";
import type { ReactNode } from "react";
import React from "react";

import ErrorBoundary from "../error/ErrorBoundary";
import { th } from "../pro-theme";

import { useNavigationState } from "./NavigationStateContext";

import { isSsgEnvironment } from "@gdco/fe-core/util/config";

export const ProPageLayout = ({ children, title }: ProPageLayoutProps) => {
  return (
    <div>
      {title}
      <div css={{ padding: "1rem 1.25rem 4rem 1.25rem" }}>
        <ErrorBoundary fullErrorPage={false}>{children}</ErrorBoundary>
      </div>
    </div>
  );
};

type ProPageLayoutProps = {
  title: ReactNode;
  children?: ReactNode;
};

export const PageTitle = ({
  title,
  contentAfter,
  imgSrc = null,
}: PageTitleProps) => {
  const nav = useNavigationState();

  return (
    <Grid container wrap="wrap" component="nav">
      {nav.isMobile && (
        <TitleMenuIcon onClick={() => nav.toggleNavigation()}>
          <DensityMedium></DensityMedium>
        </TitleMenuIcon>
      )}
      <TitleTextContainer
        item
        container
        flex={1}
        alignItems="center"
        columnGap={1}
        wrap="wrap"
      >
        <Grid
          item
          container
          alignItems="center"
          columnGap="0.75rem"
          flexGrow={1}
          width="auto"
        >
          {typeof imgSrc === "string" && (
            <TitleImage src={imgSrc} alt={title} />
          )}
          <TitleText>{title}</TitleText>
        </Grid>
        {contentAfter !== undefined && (
          <div css={{ overflowX: "auto" }}>{contentAfter}</div>
        )}
      </TitleTextContainer>
    </Grid>
  );
};
type PageTitleProps = {
  title: string;
  imgSrc?: string | null;
  contentAfter?: ReactNode;
};

export const isMobile = () => {
  if (isSsgEnvironment) return false;
  const mq =
    window.matchMedia("(max-width: 575.98px)") ||
    window.matchMedia("(min-width: 576px) and (max-width: 767.98px)");
  return mq.matches;
};

const TitleTextContainer = styled(Grid)(
  ({ theme }) => `
  min-height: 4.375rem;
  padding: 0 1.25rem;
  margin: 0;
  background-color: ${theme.palette.nav.main};
  overflow-x: hidden;
  overflow-y: hidden;
`,
);
const TitleText = styled("h2")`
  flex: 1;
  margin: 0;
  line-height: 1.5rem;
  color: ${th.sectionHeaderFg};
  font-size: 1.25rem;
`;
const TitleMenuIcon = styled("div")`
  background-color: ${th.sidebarBg};
  color: ${th.sectionHeaderFg};
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 4.375rem;
  line-height: 3rem;
  padding: 0.875rem 1.25rem 0.5rem 1.25rem;
  text-align: center;
  font-size: 1.625rem;
`;
const TitleImage = styled("img")`
  width: 3rem;
  height: 3rem;
  border-radius: ${th.imageBorderRadius};
  object-fit: cover;
`;

export const TextLarge = styled("span")`
  font-weight: bold;
  font-size: 1.25em;
`;
export const TextMuted = styled("span")`
  color: gray;
`;
