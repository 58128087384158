import { isSsgEnvironment } from "./config";

export let baseUrls: BaseUrl = {
  company: "",
  pro: "",
  plus: "",
};

export const baseUrlProduction: BaseUrl = {
  company: "https://gamediscover.co",
  pro: "https://console.gamediscover.co",
  plus: "https://plus.gamediscover.co",
};

const baseUrlLocal: BaseUrl = {
  company: "http://localhost:8003",
  pro: "http://localhost:8000",
  plus: "http://localhost:8001",
};

if (isSsgEnvironment) {
  // No access to the window object, assume production
  baseUrls = baseUrlProduction;
} else {
  // Access to the window object, check the hostname
  const hostname = window.location.hostname;
  if (hostname === "localhost") {
    baseUrls = baseUrlLocal;
  } else if (hostname.endsWith(".vercel.app")) {
    // Replace the subdomain with the correct one on Vercel preview deployments
    // This allows us to link to the same deployment from different subdomains
    baseUrls = Object.fromEntries(
      Object.keys(baseUrlProduction).map((key) => [
        key,
        "https://" + hostname.replace(process.env.GATSBY_SUBDOMAIN, key),
      ]),
    ) as BaseUrl;
  } else {
    baseUrls = baseUrlProduction;
  }
}

type BaseUrl = Record<NodeJS.ProcessEnv["GATSBY_SUBDOMAIN"], string>;
