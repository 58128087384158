import { useTheme } from "@mui/material";
import type { ReactNode } from "react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useEventListener, useWindowSize } from "usehooks-ts";

import type { ProNavigationStateValue } from "@gdco/fe-core/util/config";
import {
  isSsgEnvironment,
  ProNavigationState,
} from "@gdco/fe-core/util/config";
import type { LocalStorageCurrentTabEvent } from "@gdco/fe-core/util/storage";
import {
  gdcoStorage,
  getStorageKey,
  LOCAL_STORAGE_EVENT_CURRENT_TAB,
  LOCAL_STORAGE_EVENT_OTHER_TAB,
} from "@gdco/fe-core/util/storage";

const NavigationStateContext = createContext<NavigationContext>(
  {} as NavigationContext,
);

export function NavigationStateProvider({
  children,
}: NavigationStateProviderProps) {
  const theme = useTheme();

  let { width } = useWindowSize();
  if (width === 0) {
    // Use the default width for SSR
    width = theme.breakpoints.values.lg;
  }
  const mobileBreakpoint = theme.breakpoints.values.sm;
  const smallDesktopBreakpoint = theme.breakpoints.values.md;
  const isMobile = width < mobileBreakpoint;
  const isSmallerDesktopScreen = !isMobile && width < smallDesktopBreakpoint;

  const [navigation, setNavigation] = useState<ProNavigationStateValue>(() => {
    // Set initial navigation state based on the screen size

    if (isSsgEnvironment) {
      return ProNavigationState.FULL;
    }

    const isMobileMatch = window.matchMedia(
      `(max-width: ${mobileBreakpoint}px)`,
    );
    if (isMobileMatch.matches) {
      return ProNavigationState.HIDDEN;
    }

    const isSmallerDesktopScreenMatch = window.matchMedia(
      `(max-width: ${smallDesktopBreakpoint}px)`,
    );
    if (isSmallerDesktopScreenMatch.matches) {
      return ProNavigationState.SMALL;
    }

    const storedNavigationState = gdcoStorage.local.getItem("navigationState");
    return storedNavigationState;
  });

  useEffect(() => {
    if (isSmallerDesktopScreen) {
      // Default to the small navigation state on smaller desktop screens
      setNavigation(ProNavigationState.SMALL);
    } else if (!isMobile) {
      // Restore the navigation state from local storage when returning to larger desktop screens
      const storedNavigationState =
        gdcoStorage.local.getItem("navigationState");
      setNavigation(storedNavigationState);
      return;
    }

    // Update the navigation state when isMobile changes
    const storedNavigationState = gdcoStorage.local.getItem("navigationState");
    setNavigation((prev) => {
      if (isMobile && prev !== ProNavigationState.HIDDEN) {
        // Mobile size, always hide the navigation
        return ProNavigationState.HIDDEN;
      }
      if (!isMobile && prev === ProNavigationState.HIDDEN) {
        // Desktop size, show the navigation if it was previously hidden
        return storedNavigationState > ProNavigationState.HIDDEN
          ? storedNavigationState
          : ProNavigationState.FULL;
      }
      return prev;
    });
  }, [isSmallerDesktopScreen, isMobile]);

  /**
   * Handle changes to the navigation state in local storage
   */
  function handleStorageChange(event: StorageEvent | CustomEvent) {
    let nextValue: ProNavigationStateValue | undefined;
    const localStorageKey = getStorageKey("navigationState");
    if (
      event.type === LOCAL_STORAGE_EVENT_OTHER_TAB &&
      (event as StorageEvent).key === localStorageKey
    ) {
      nextValue = Number(
        (event as StorageEvent).newValue,
      ) as ProNavigationStateValue;
    } else if (event.type === LOCAL_STORAGE_EVENT_CURRENT_TAB) {
      const eventDetail = (event as LocalStorageCurrentTabEvent).detail;
      if (eventDetail.key === localStorageKey) {
        nextValue = Number(eventDetail.value) as ProNavigationStateValue;
      }
    }

    if (nextValue !== undefined) {
      setNavigation(nextValue);
    }
  }

  // Listen for changes to the navigation state
  useEventListener(LOCAL_STORAGE_EVENT_OTHER_TAB, (event) =>
    handleStorageChange(event),
  );
  useEventListener(LOCAL_STORAGE_EVENT_CURRENT_TAB, (event) =>
    handleStorageChange(event),
  );

  /**
   * Update the navigation state in local storage
   */
  function storeNavigation(nextState: ProNavigationStateValue) {
    if (nextState === ProNavigationState.HIDDEN) {
      // Update only the local state when hiding the navigation
      setNavigation(nextState);
      return;
    }
    gdcoStorage.local.setItem("navigationState", nextState);
  }

  function toggleNavigation() {
    let nextNavigationState: ProNavigationStateValue;
    if (isMobile) {
      // Toggle between hidden and full
      nextNavigationState =
        navigation === ProNavigationState.HIDDEN
          ? ProNavigationState.FULL
          : ProNavigationState.HIDDEN;
    } else {
      // Toggle between small and full
      nextNavigationState =
        navigation === ProNavigationState.SMALL
          ? ProNavigationState.FULL
          : ProNavigationState.SMALL;
    }
    if (nextNavigationState === ProNavigationState.HIDDEN) {
      // Update only the local state when hiding the navigation
      setNavigation(nextNavigationState);
    } else {
      // Store the new state in local storage
      storeNavigation(nextNavigationState);
    }
  }

  return (
    <NavigationStateContext.Provider
      value={{
        isMobile,
        navigation,
        setNavigation: storeNavigation,
        toggleNavigation,
      }}
    >
      {children}
    </NavigationStateContext.Provider>
  );
}

/**
 * State getter/setter for the navigation state.
 *
 * @example const { navigationState, setNavigationState } = useNavigationState();
 */
export function useNavigationState() {
  const context = useContext(NavigationStateContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationState must be used within a NavigationStateContext.Provider",
    );
  }
  return context;
}

export type NavigationContext = {
  /** Whether the current screen is considered mobile */
  isMobile: boolean;
  /** The current navigation state */
  navigation: number;
  /** Update the navigation state */
  setNavigation: (value: ProNavigationStateValue) => void;
  /** Toggle the navigation state between small and full */
  toggleNavigation: () => void;
};

type NavigationStateProviderProps = {
  children: ReactNode;
};
