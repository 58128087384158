/**
 * Utils for handling the query string
 */

import type { IParseOptions, IStringifyOptions } from "qs";
import { parse, stringify } from "qs";
import { useMemo } from "react";
import type { z, ZodDefault, ZodObject, ZodTypeAny } from "zod";

/** Stringify the query to a common query string format */
export function stringifyQueryString(
  query: Record<string, unknown>,
  options?: IStringifyOptions,
) {
  return stringify(query, {
    arrayFormat: "indices",
    allowDots: true,
    skipNulls: false,
    addQueryPrefix: true,
    ...options,
  });
}

/**
 * Parses the query string from a common query string format to an object
 *
 * Takes care of replacing the broken ampersand (thanks, Substack!)
 */
export function parseQueryString(queryString: string, options?: IParseOptions) {
  const searchParams = parse(replaceBrokenAmpersand(queryString), {
    allowDots: true,
    ignoreQueryPrefix: true,
    ...options,
  });
  return searchParams;
}

/**
 * Memoized hook to parse the query string with a Zod schema
 *
 * Requires providing the default value for each schema key.
 */
export function useParseQueryString<
  TShape extends Record<string, ZodDefault<ZodTypeAny>>,
>(search: string, schema: ZodObject<TShape>) {
  const data = useMemo(() => {
    const query = parseQueryString(search);
    const parsed = schema.safeParse(query);
    if (parsed.success) {
      return parsed.data;
    }
    return {} as z.infer<typeof schema>;
  }, [schema, search]);

  return data;
}

/**
 * Fix broken URL search parameters by replacing the ampersand encoding
 *
 * When sharing a newsletter via email, Substack replaces the '&' with an unusual HTML encoding '&amp%3B':
 * - BROKEN: ?t.date=2022-01-01&amp%3Bt.days=90&utm_source=substack&utm_medium=email
 * - FIXED:  ?t.date=2022-01-01&t.days=90&utm_source=substack&utm_medium=email
 */
export const replaceBrokenAmpersand = (
  urlSearchParams: ConstructorParameters<typeof URLSearchParams>[0],
) => {
  if (typeof urlSearchParams === "string") {
    return urlSearchParams.replace("&amp%3B", "&");
  }
  return "";
};
