import type { AnchorHTMLAttributes, ReactNode } from "react";
import React, { forwardRef } from "react";

import { a_rel } from "../../fetch/route-internal";

const ExternalLink = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement> & ExternalLinkExtraProps
>(({ children, relType = "default", ...props }, ref) => {
  return (
    <a {...props} rel={props.rel ?? a_rel[relType]} target="_blank" ref={ref}>
      {children}
    </a>
  );
});
ExternalLink.displayName = "ExternalLink";

export type ExternalLinkExtraProps = {
  /**
   * `rel` has priority over `relType` if both are provided
   *
   * @default "default"
   */
  relType?: keyof typeof a_rel;
  children: ReactNode;
};

export default ExternalLink;
