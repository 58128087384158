import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import ms from "ms";
import type { VariantType } from "notistack";
import { enqueueSnackbar } from "notistack";
import type { CSSProperties } from "react";
import React from "react";
import { match } from "ts-pattern";

import { isBrowserEnvironment, isProDomain } from "../util/config";
import { gdcoStorage } from "../util/storage";

import {
  FetchError,
  hasTooManyRequests,
  unknownFetchErrorCode,
} from "./fetch-error";
import { routeInternal } from "./route-internal";

const MAX_FETCH_RETRIES = 1;

/**
 * Define global query client settings here.
 * This query client is as default for every react-query useQuery.
 * @see https://tanstack.com/query/v4/docs/reference/QueryClient
 *
 * Important defaults: https://tanstack.com/query/v4/docs/guides/important-defaults
 *
 * Guidelines:
 * - use placeholderData for setting placeholder data
 *   @see https://tanstack.com/query/v4/docs/guides/placeholder-query-data
 * - gotcha: `staleTime:Infinity` + `initialData` will never refetch the initial data
 *   @see https://tanstack.com/query/v4/docs/guides/initial-query-data
 * - `staleTime:Infinity` saves network requests in production but requires constant reload while developing
 * - errors must be thrown to trigger useQuery.isError
 *   @see https://tanstack.com/query/v4/docs/guides/query-functions#handling-and-throwing-errors
 */
export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: process.env.NODE_ENV === "development" ? "always" : "online",
      // development: data is stale only shortly -> refetch
      // production: keep data for a while
      staleTime: process.env.NODE_ENV === "development" ? ms("10s") : ms("2h"),
      throwOnError: false,
      retry: (failureCount, error) => {
        if (failureCount > MAX_FETCH_RETRIES) {
          return false;
        }

        if (error instanceof FetchError) {
          // Check for too many requests
          if (
            (Number(error.code) as StatusCodes) ===
            StatusCodes.TOO_MANY_REQUESTS
          ) {
            const parsed429 = gdcoStorage.local.getItem("tooManyRequests");
            if (hasTooManyRequests(parsed429)) {
              return false;
            }
          }

          // Check for other errors
          return (
            [StatusCodes.NOT_FOUND, StatusCodes.UNPROCESSABLE_ENTITY].includes(
              Number(error.code),
            ) === false
          );
        }
        return failureCount < MAX_FETCH_RETRIES;
      },
    },
  },
  queryCache: new QueryCache({
    onError: onReactQueryCacheError,
  }),
  mutationCache: new MutationCache({
    onError: onReactQueryCacheError,
  }),
});

const snackbarButtonStyle: CSSProperties = {
  color: "white",
  textDecoration: "none",
  fontWeight: "bolder",
};

const retryActions = (
  <div style={{ display: "flex", gap: "1rem" }}>
    <a
      href={isBrowserEnvironment ? window.location.href : "#"}
      onClick={() => {
        window.location.reload();
      }}
      style={snackbarButtonStyle}
    >
      RELOAD
    </a>
    <a href={routeInternal.home.to} style={snackbarButtonStyle}>
      GO HOME
    </a>
  </div>
);

/**
 * Handles errors that occur in the React Query cache
 *
 * @param error The error object (FetchError or other)
 */
function onReactQueryCacheError(error: unknown) {
  if (!(error instanceof FetchError)) {
    console.error("unknown reactQueryClient error:", error);
    return;
  }

  const code = Number(error.code) as StatusCodes;
  const keyPrefix = "fetch-error-";

  match(code)
    .with(StatusCodes.TOO_MANY_REQUESTS, (c) => {
      enqueueSnackbar("Too many requests", {
        variant: "info",
        preventDuplicate: true,
        key: `${keyPrefix}${c}`,
      });
    })
    .with(StatusCodes.NOT_FOUND, (c) => {
      enqueueSnackbar("No results found", {
        variant: "warning",
        preventDuplicate: true,
        key: `${keyPrefix}${c}`,
        action: retryActions,
      });
    })
    .with(StatusCodes.UNPROCESSABLE_ENTITY, (c) => {
      enqueueSnackbar(
        <div>
          <p>
            <strong>Invalid data sent to the server</strong>
          </p>
          <p>Check the form or URL for typos</p>
        </div>,
        {
          variant: "warning",
          preventDuplicate: true,
          key: `${keyPrefix}${c}`,
          action: retryActions,
        },
      );
    })
    .when(
      () =>
        isProDomain &&
        (code === StatusCodes.INTERNAL_SERVER_ERROR ||
          code === unknownFetchErrorCode),
      () => {
        // Don't show 500 and 418 errors on the Console domain
        // as there are too many of them especially on the details page due to timeouts
        console.error("fetch error", error);
      },
    )
    .with(unknownFetchErrorCode, () => {
      console.error("fetch error with unknown error code", error);
    })
    .otherwise((c) => {
      // Errors showing an error code and a message

      let variant: VariantType = "error";
      if (code < StatusCodes.INTERNAL_SERVER_ERROR) {
        variant = "warning";
      }

      const errorPhrase = getReasonPhrase(code);
      const notificationMessage = `Failed to load data: ${
        Number.isInteger(code) ? `${code} ${errorPhrase}. ` : ""
      }${error.message}`;
      enqueueSnackbar(notificationMessage, {
        variant,
        preventDuplicate: true,
        key: `${keyPrefix}${c}`,
      });
    });
}
