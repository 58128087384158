import MuiAppleIcon from "@mui/icons-material/Apple";
import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import React, { memo } from "react";

export type CustomSvgIconProps = Omit<SvgIconProps, "titleAccess"> & {
  /** Set to null to disable the title */
  titleAccess?: string | null;
};

export const AllPlatformsIcon = memo(
  (_props: CustomSvgIconProps): JSX.Element => {
    return <svg />;
  },
);
AllPlatformsIcon.displayName = "AllPlatformsIcon";

export const AppleIcon = memo(
  ({ titleAccess = "Apple", ...svgProps }: CustomSvgIconProps) => (
    <MuiAppleIcon titleAccess={titleAccess ?? undefined} {...svgProps} />
  ),
);
AppleIcon.displayName = "AppleIcon";

export const DiscordIcon = memo(
  ({ titleAccess = "Discord", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="41 19 166 200"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <g style={{ fill: "#7289da" }}>
        <path d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zm36.5 0c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
        <path d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z" />
      </g>
    </SvgIcon>
  ),
);
DiscordIcon.displayName = "DiscordIcon";

export const EpicIcon = memo(
  ({ titleAccess = "Epic Games Store", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="0 -6 31 44"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <g fillRule="evenodd">
        <path d="M9.118 28.675h1.823l-.895-1.85zM12.765 12.025h1.823V5.55h-1.823z" />
        <path d="M29.218 8.363h-2.374V5.06h-1.878c-.015 0-.044 0-.066.007V21.46h1.951v-3.236h2.36v3.403c0 2.238-2.17 2.253-2.214 2.253h-2.082c-2.41 0-2.403-2.268-2.403-2.268V4.885c0-2.246 2.403-2.246 2.403-2.246h2.082c2.22 0 2.22 2.246 2.22 2.246v3.478zm-.634 20.478c0 .718-.233.907-.94.907h-2.322c-.706 0-.939-.181-.939-.907v-.106l.772-.166v.453h2.657v-.877h-2.344c-.706 0-.932-.181-.932-.907v-.424c0-.718.226-.907.932-.907h2.133c.67 0 .932.181.932.84v.082l-.772.182v-.439h-2.46v.832h2.344c.706 0 .94.182.94.907v.53zm-5.147-2.276h-2.97v.854h1.732v.65h-1.733v.946h2.963v.718h-3.837v-3.819h3.845v.65zm-7.965 8.09L9.086 32.07h12.77l-6.384 2.586zm-7.04-4.9h-.875l2.126-3.826h.845l2.155 3.827H11.7l-.451-.84H8.868l-.437.84zm-1.617-2.873l-.852.159v-.431h-2.73v2.42h2.73v-.855H4.536v-.65h2.279v1.316c0 .718-.226.907-.932.907H3.29c-.706 0-.932-.181-.932-.907v-2.012c0-.718.226-.907.932-.907h2.592c.684 0 .932.181.932.87v.09zm-5.213-5.27V4.885C1.602 2.63 4.019 2.6 4.04 2.6h4.295v2.488H4.055v6.889h3.218v2.533H4.055v6.896h4.289v2.496H4.04v.007c-.765 0-1.369-.226-1.791-.665-.648-.673-.648-1.626-.648-1.633zm8.38-18.973h4.485c2.213 0 2.22 2.246 2.22 2.246v7.32c0 2.245-2.176 2.253-2.213 2.253h-2.111v9.415H9.982V2.639zm8.497 23.29v3.827h-.809v-2.367c0-.076.008-.204.03-.408-.08.173-.153.31-.219.4l-1.412 1.913h-.175l-1.413-1.92a1.705 1.705 0 01-.225-.393c.014.158.022.28.022.408v2.367h-.707v-3.827h.707l1.769 2.314 1.732-2.314h.7zm-.124-2.056h2.497V2.639h-2.497v21.234zm12.29-21.43a4.052 4.052 0 00-.816-1.256A3.766 3.766 0 0028.621.34a3.564 3.564 0 00-1.478-.318H3.8c-.51 0-1.005.106-1.478.318-.452.197-.86.484-1.21.847C.765 1.55.489 1.973.3 2.442A4.12 4.12 0 000 3.985V30.73L15.472 37l15.471-6.269V3.985a4.12 4.12 0 00-.298-1.543z" />
      </g>
    </SvgIcon>
  ),
);
EpicIcon.displayName = "EpicIcon";

export const NintendoIcon = memo(
  ({ titleAccess = "Nintendo Switch", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="-42 -50 630 630"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <path d="M136.5 34.6c-48.1 8.6-86.8 44.2-99 91-4.4 16.9-4.7 26.8-4.2 157.9.3 120.4.4 123.2 2.4 132.5 11.1 50.1 46.7 85.8 97.3 97.6 6.6 1.5 15 1.8 69.1 2.1 56 .4 61.8.3 63.3-1.2s1.6-20.8 1.6-239.6c0-162.2-.3-238.6-1-240-1-1.8-2.7-1.9-61.3-1.8-47.6.1-61.9.4-68.2 1.5zm90.5 240v202.6l-40.7-.5c-37.5-.4-41.5-.6-49-2.5-32.2-8.3-56.1-33.1-62.8-65.4-2.2-10.1-2.2-258.9-.1-268.8 6-28.1 25.5-51.4 51.8-61.9 13.2-5.3 19.3-5.9 62.1-6L227 72v202.6z" />
      <path d="M145.1 133.1c-6.3 1.2-15.9 6-21 10.5-10.5 9.1-15.7 22-14.9 37.2.4 7.9.9 10 4.1 16.4 4.7 9.7 11.8 16.8 21.5 21.6 6.7 3.3 8.4 3.7 17.1 4 7.9.3 10.7 0 16-1.8 21.7-7.3 34.8-28.4 31.1-50-4.3-25.7-28.7-42.9-53.9-37.9zM315.7 33.7c-.4.3-.7 108.7-.7 240.9 0 218.1.1 240.2 1.6 240.8 2.7 1 80.3.6 89.9-.4 40.6-4.6 76.4-29.3 95.9-66 2.5-4.7 5.8-12.6 7.5-17.5 6.3-18.8 6.1-13.8 6.1-157.5 0-114.7-.2-131.8-1.6-139.1-9.9-52.1-49.6-91.4-101.8-100.4-7-1.2-17.8-1.5-52.6-1.5-24 0-44 .3-44.3.7zm106.8 217.8c15.6 4.1 28.4 16 33.6 31.2 3.3 9.4 3.2 23.2-.1 31.8-6.1 15.8-18.1 26.9-33.5 31-25 6.5-51.6-8.6-58.9-33.4-2.2-7.6-2.1-20.4.4-28.2 7.5-24.5 33.5-38.9 58.5-32.4z" />
    </SvgIcon>
  ),
);
NintendoIcon.displayName = "NintendoIcon";

export const MetaIcon = memo(
  ({ titleAccess = "Meta", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="0 0 287.56 191"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <path d="M31.06 126c0 11 2.41 19.41 5.56 24.51A19 19 0 0 0 53.19 160c8.1 0 15.51-2 29.79-21.76 11.44-15.83 24.92-38 34-52l15.36-23.6c10.67-16.39 23-34.61 37.18-47C181.07 5.6 193.54 0 206.09 0c21.07 0 41.14 12.21 56.5 35.11 16.81 25.08 25 56.67 25 89.27 0 19.38-3.82 33.62-10.32 44.87C271 180.13 258.72 191 238.13 191v-31c17.63 0 22-16.2 22-34.74 0-26.42-6.16-55.74-19.73-76.69-9.63-14.86-22.11-23.94-35.84-23.94-14.85 0-26.8 11.2-40.23 31.17-7.14 10.61-14.47 23.54-22.7 38.13l-9.06 16c-18.2 32.27-22.81 39.62-31.91 51.75C84.74 183 71.12 191 53.19 191c-21.27 0-34.72-9.21-43-23.09C3.34 156.6 0 141.76 0 124.85Z" />
      <path d="M24.49 37.3C38.73 15.35 59.28 0 82.85 0c13.65 0 27.22 4 41.39 15.61 15.5 12.65 32 33.48 52.63 67.81l7.39 12.32c17.84 29.72 28 45 33.93 52.22 7.64 9.26 13 12 19.94 12 17.63 0 22-16.2 22-34.74l27.4-.86c0 19.38-3.82 33.62-10.32 44.87C271 180.13 258.72 191 238.13 191c-12.8 0-24.14-2.78-36.68-14.61-9.64-9.08-20.91-25.21-29.58-39.71L146.08 93.6c-12.94-21.62-24.81-37.74-31.68-45-7.4-7.89-16.89-17.37-32.05-17.37-12.27 0-22.69 8.61-31.41 21.78Z" />
      <path d="M82.35 31.23c-12.27 0-22.69 8.61-31.41 21.78C38.61 71.62 31.06 99.34 31.06 126c0 11 2.41 19.41 5.56 24.51l-26.48 17.4C3.34 156.6 0 141.76 0 124.85 0 94.1 8.44 62.05 24.49 37.3 38.73 15.35 59.28 0 82.85 0Z" />
    </SvgIcon>
  ),
);
MetaIcon.displayName = "MetaIcon";

export const PlaystationIcon = memo(
  ({ titleAccess = "PlayStation", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="0 -5 200 160"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <path d="M197.24 117.962c-3.869 4.88-13.344 8.36-13.344 8.36l-70.492 25.32V132.97l51.877-18.484c5.887-2.11 6.791-5.091 2.006-6.657-4.776-1.57-13.425-1.12-19.316.999l-34.567 12.174v-19.379l1.993-.674s9.988-3.535 24.034-5.091c14.045-1.547 31.242.211 44.743 5.329 15.215 4.808 16.928 11.895 13.065 16.775zm-77.125-31.796V38.413c0-5.608-1.035-10.77-6.297-12.232-4.03-1.291-6.53 2.45-6.53 8.054V153.82l-32.25-10.236V1c13.712 2.545 33.69 8.563 44.429 12.183 27.312 9.377 36.572 21.048 36.572 47.344 0 25.63-15.821 35.344-35.924 25.639zM15.862 131.018c-15.62-4.399-18.219-13.564-11.1-18.844 6.58-4.875 17.77-8.545 17.77-8.545l46.241-16.442v18.745L35.497 117.84c-5.878 2.109-6.782 5.095-2.005 6.66 4.78 1.565 13.433 1.12 19.32-.994l15.961-5.792v16.77c-1.012.18-2.14.36-3.184.535-15.966 2.609-32.97 1.52-49.727-4.002zm171.105 7.425c2.02 0 3.913.782 5.339 2.226a7.487 7.487 0 012.212 5.334 7.472 7.472 0 01-2.212 5.334 7.465 7.465 0 01-5.339 2.217 7.472 7.472 0 01-5.338-2.217 7.467 7.467 0 01-2.208-5.334c0-4.165 3.382-7.56 7.546-7.56zm-6.278 7.56c0 1.677.652 3.256 1.84 4.434a6.2 6.2 0 004.438 1.844 6.281 6.281 0 006.279-6.278c0-1.682-.648-3.26-1.84-4.444a6.195 6.195 0 00-4.439-1.839 6.195 6.195 0 00-4.439 1.84 6.227 6.227 0 00-1.839 4.443zm8.558-4.07c.828.364 1.246 1.066 1.246 2.064 0 .513-.108.944-.328 1.282-.158.243-.382.44-.634.611.198.117.373.257.503.419.18.233.284.607.297 1.115l.04 1.075c.014.288.032.508.077.643.045.198.13.324.239.364l.112.054v.49h-.184-1.336-.108l-.054-.094a1.477 1.477 0 01-.108-.328 5.105 5.105 0 01-.058-.657l-.068-1.336c-.018-.463-.175-.75-.472-.904-.185-.085-.49-.125-.904-.125h-2.29v3.444h-.188-1.088-.185v-.184-8.037-.184h3.904c.638 0 1.174.094 1.587.288zm-4.03 3.386h2.33c.468 0 .841-.085 1.107-.26.243-.176.36-.495.36-.959 0-.503-.167-.832-.518-1.007-.193-.094-.463-.148-.805-.148h-2.473v2.374z" />
    </SvgIcon>
  ),
);
PlaystationIcon.displayName = "PlaystationIcon";

export const SteamIcon = memo(
  ({ titleAccess = "Steam", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="-5 -6 48 47"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <path d="M19.952 13.353c0-1.876 1.483-3.397 3.31-3.397s3.309 1.52 3.309 3.397c0 1.876-1.482 3.397-3.31 3.397-1.826 0-3.309-1.52-3.309-3.397m7.707.008c0-2.494-1.976-4.525-4.404-4.525-2.43 0-4.406 2.03-4.406 4.525 0 2.493 1.976 4.522 4.406 4.522 2.428 0 4.404-2.029 4.404-4.522M10.986 27.312l-2.154-.914a3.742 3.742 0 0 0 1.919 1.874c1.895.811 4.08-.112 4.869-2.059a3.895 3.895 0 0 0 .006-2.926 3.752 3.752 0 0 0-2.01-2.074 3.624 3.624 0 0 0-2.746-.042l2.225.944c1.397.599 2.058 2.246 1.475 3.681-.581 1.435-2.187 2.114-3.584 1.516M17.47 0C8.26 0 .716 7.291 0 16.556l9.396 3.989a4.839 4.839 0 0 1 3.069-.878l4.178-6.22v-.087c0-3.742 2.966-6.788 6.611-6.788 3.646 0 6.611 3.046 6.611 6.788 0 3.743-2.965 6.79-6.61 6.79-.051 0-.1-.002-.15-.003l-5.96 4.365c.003.08.006.162.006.241 0 2.811-2.227 5.097-4.963 5.097-2.401 0-4.41-1.761-4.865-4.092l-6.72-2.853C2.684 30.461 9.445 36 17.47 36 27.152 36 35 27.94 35 18c0-9.941-7.848-18-17.53-18" />
    </SvgIcon>
  ),
);
SteamIcon.displayName = "SteamIcon";

export const SteamDbIcon = memo(
  ({ titleAccess = "SteamDB", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="0 0 128 128"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        d="M63.9 0C30.5 0 3.1 11.9.1 27.1l35.6 6.7c2.9-.9 6.2-1.3 9.6-1.3l16.7-10c-.2-2.5 1.3-5.1 4.7-7.2 4.8-3.1 12.3-4.8 19.9-4.8 5.2-.1 10.5.7 15 2.2 11.2 3.8 13.7 11.1 5.7 16.3-5.1 3.3-13.3 5-21.4 4.8l-22 7.9c-.2 1.6-1.3 3.1-3.4 4.5-5.9 3.8-17.4 4.7-25.6 1.9-3.6-1.2-6-3-7-4.8L2.5 38.4c2.3 3.6 6 6.9 10.8 9.8C5 53 0 59 0 65.5c0 6.4 4.8 12.3 12.9 17.1C4.8 87.3 0 93.2 0 99.6 0 115.3 28.6 128 64 128c35.3 0 64-12.7 64-28.4 0-6.4-4.8-12.3-12.9-17 8.1-4.8 12.9-10.7 12.9-17.1 0-6.5-5-12.6-13.4-17.4 8.3-5.1 13.3-11.4 13.3-18.2 0-16.5-28.7-29.9-64-29.9zm22.8 14.2c-5.2.1-10.2 1.2-13.4 3.3-5.5 3.6-3.8 8.5 3.8 11.1 7.6 2.6 18.1 1.8 23.6-1.8s3.8-8.5-3.8-11c-3.1-1-6.7-1.5-10.2-1.5zm.3 1.7c7.4 0 13.3 2.8 13.3 6.2 0 3.4-5.9 6.2-13.3 6.2s-13.3-2.8-13.3-6.2c0-3.4 5.9-6.2 13.3-6.2zM45.3 34.4c-1.6.1-3.1.2-4.6.4l9.1 1.7a10.8 5 0 1 1-8.1 9.3l-8.9-1.7c1 .9 2.4 1.7 4.3 2.4 6.4 2.2 15.4 1.5 20-1.5s3.2-7.2-3.2-9.3c-2.6-.9-5.7-1.3-8.6-1.3zM109 51v9.3c0 11-20.2 19.9-45 19.9-24.9 0-45-8.9-45-19.9v-9.2c11.5 5.3 27.4 8.6 44.9 8.6 17.6 0 33.6-3.3 45.2-8.7zm0 34.6v8.8c0 11-20.2 19.9-45 19.9-24.9 0-45-8.9-45-19.9v-8.8c11.6 5.1 27.4 8.2 45 8.2s33.5-3.1 45-8.2z"
      />
    </SvgIcon>
  ),
);
SteamDbIcon.displayName = "SteamDbIcon";

export const XboxIcon = memo(
  ({ titleAccess = "Xbox", ...svgProps }: CustomSvgIconProps) => (
    <SvgIcon
      aria-hidden={false}
      viewBox="-5 -6 47 47"
      titleAccess={titleAccess ?? undefined}
      {...svgProps}
    >
      <path d="M16.205 35.926c-2.773-.265-5.58-1.26-7.993-2.834-2.02-1.317-2.477-1.86-2.477-2.94 0-2.172 2.39-5.976 6.478-10.312 2.32-2.463 5.554-5.348 5.903-5.27.68.151 6.116 5.45 8.151 7.945 3.218 3.943 4.696 7.173 3.945 8.613-.571 1.095-4.116 3.234-6.72 4.056-2.146.677-4.965.964-7.287.742zm-13.2-8.032C1.327 25.32.479 22.784.069 19.117c-.135-1.21-.087-1.902.307-4.388.491-3.095 2.255-6.678 4.377-8.882.903-.937.983-.962 2.084-.59 1.335.452 2.762 1.437 4.975 3.438l1.293 1.168-.707.866c-3.275 4.018-6.732 9.718-8.033 13.243-.708 1.916-.992 3.84-.687 4.64.205.541.017.34-.673-.717h.002zm29.48.438c.165-.81-.045-2.296-.536-3.796-1.065-3.247-4.625-9.287-7.895-13.395l-1.029-1.293 1.114-1.021c1.454-1.334 2.463-2.133 3.552-2.812.86-.535 2.087-1.009 2.615-1.009.325 0 1.472 1.192 2.396 2.488 1.433 2.008 2.486 4.446 3.02 6.98.346 1.64.374 5.143.056 6.779-.264 1.341-.816 3.08-1.352 4.26-.405.883-1.405 2.6-1.845 3.158-.226.288-.226.287-.1-.332l.003-.007zM16.501 4.393c-1.509-.765-3.836-1.587-5.121-1.809a9.35 9.35 0 00-1.707-.096c-1.062.054-1.014-.001.687-.805 1.414-.668 2.594-1.06 4.197-1.396 1.801-.379 5.188-.383 6.96-.01 1.915.404 4.17 1.242 5.44 2.024l.378.232-.866-.043c-1.722-.087-4.231.608-6.926 1.918-.813.395-1.519.71-1.57.702-.052-.01-.715-.332-1.474-.717h.002z" />
    </SvgIcon>
  ),
);
XboxIcon.displayName = "XboxIcon";
