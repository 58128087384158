export const isBrowserEnvironment = typeof window !== "undefined";
export const isSsgEnvironment = !isBrowserEnvironment;

export const isPlusDomain = process.env.GATSBY_SUBDOMAIN === "plus";
export const isProDomain = process.env.GATSBY_SUBDOMAIN === "pro";

export const subdomainName =
  process.env.GATSBY_SUBDOMAIN === undefined
    ? ""
    : isProDomain
      ? "Console"
      : process.env.GATSBY_SUBDOMAIN.charAt(0).toUpperCase() +
        process.env.GATSBY_SUBDOMAIN.slice(1);

/**
 * Quick access to feature flags
 *
 * Add the environment variable to types/env.d.ts, .env and to Vercel's environment variables.
 *
 * @example newFeature: process.env.GATSBY_FEATURE_NEW_FEATURE === "true"
 */
export const featureFlags = {};

/**
 * Navigation state for the sidebar
 *
 * It must be in this library to prevent circular dependencies.
 */
export const ProNavigationState = {
  /** Hidden mobile sidebar */
  HIDDEN: 0,
  /** Small desktop sidebar */
  SMALL: 1,
  /** Full desktop sidebar or visible mobile sidebar */
  FULL: 2,
} as const;
export type ProNavigationStateValue =
  (typeof ProNavigationState)[keyof typeof ProNavigationState];
