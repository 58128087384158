import { nanoid } from "nanoid";
import type { ReactNode } from "react";
import React, { Fragment } from "react";

/**
 * Joins an array with a node
 */
export function jsxJoin(
  array: ReactNode[],
  separator: ReactNode,
  ignoreEmpty = true,
) {
  let nodes = array;
  if (ignoreEmpty) {
    nodes = array.filter((item) => item !== null && item !== undefined);
  }

  return nodes.length > 0
    ? // eslint-disable-next-line unicorn/no-array-reduce -- need this here
      nodes.reduce((result, item, i) => (
        <Fragment key={nanoid()}>
          {result}
          {separator}
          {item}
        </Fragment>
      ))
    : null;
}
